import { Button, Form, Input, InputNumber, Typography } from "antd";
import { AiOutlineSave } from "react-icons/ai";
import { IoCogOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { useCTX } from "../../state";
import { setAlert, setUser } from "../../state/actionCreators";

type TInfo = {
  display_name: string;
  rows_per_page: number;
};

export default function Settings() {
  useTitle("Settings");

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const [request, loading] = useRequest();

  const onSave = (values: TInfo) => {
    const _info = { ...values, token: localStorage.getItem("token") };

    request(
      "post",
      "users/api/websettings/",
      () => {
        navigate(-1);
        dispatch(
          setUser({
            ...user!,
            ...values,
          })
        );
        dispatch(
          setAlert({
            type: "success",
            message: "SETTINGS",
            description: "Settings updated successfully!",
          })
        );
      },
      _info
    );
  };

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center w-full pb-4"
        >
          <IoCogOutline className="mr-2" /> Settings
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          className="w-full"
          initialValues={{
            display_name: user?.display_name,
            rows_per_page: user?.rows_per_page,
          }}
          onFinish={onSave}
        >
          <Form.Item label="Display Name" name="display_name">
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Rows per page"
            name="rows_per_page"
            rules={[
              {
                required: true,
                message: "Please input the number of rows per page!",
              },
            ]}
          >
            <InputNumber min={4} />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<AiOutlineSave size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PrivateRoute>
  );
}
