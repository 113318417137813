import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  MdAdd,
  MdDeleteOutline,
  MdEdit,
  MdOutlineFactCheck,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IDispute, IFact } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function FactList() {
  useTitle("Fact List");

  const [facts, setFacts] = useState<IFact[]>([]);
  const [deleteFact, setDeleteFact] = useState<IFact | null>(null);

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();

  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IFact>["columns"] = useMemo(
    () => [
      {
        key: "disputeId",
        title: "Dispute ID",
        dataIndex: "disputeId",
        render: (_: any, f: any) => <span>{f.dispute.disputeId}</span>,
      },
      {
        key: "title",
        title: "Title",
        dataIndex: "title",
      },
      {
        key: "created",
        title: "Created",
        dataIndex: "created",
        render: (_: any, f: any) => (
          <span>{new Date(f.creation_date).toLocaleDateString()}</span>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, f: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteFact(f)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() =>
                navigate(`/provider/dispute-facts/${f.factId}/edit`)
              }
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "disputes/api/factRemove/",
      () => {
        setFacts((old) => old.filter((o) => o.factId !== deleteFact?.factId));
        setDeleteFact(null);
        dispatch(
          setAlert({
            type: "success",
            message: "FACT",
            description: "Dispute fact deleted successfully!",
          })
        );
      },
      {
        email: user?.email,
        factId: deleteFact?.factId,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "disputes/api/disputesList/",
      (res) => {
        setFacts(
          res.data.disp_list
            .map((d: IDispute) => d.Facts.map((f) => ({ ...f, dispute: d })))
            .flat()
        );
      },
      {
        email: user?.email,
        pageRequested: 1,
        isProvider: true,
      }
    );
  }, [user?.email, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteFact}
        title="Delete Fact"
        onCancel={() => setDeleteFact(null)}
        okText="Delete"
        onOk={onDelete}
        confirmLoading={deleting}
      >
        <span className="text-xs">
          Are you sure that you want to delete fact '{deleteFact?.title}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <MdOutlineFactCheck className="mr-2" /> Dispute Facts
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/provider/dispute-facts/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={facts} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
