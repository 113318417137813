import { Navigate, Outlet } from "react-router-dom";
import { useCTX } from "../../state";

export default function Validator() {
  const {
    state: { settings },
  } = useCTX();

  if (settings.type !== "customer") {
    return <Navigate to={"/"} />;
  }

  return <Outlet />;
}
