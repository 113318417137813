import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { IoMdBusiness } from "react-icons/io";
import { MdAdd, MdDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IEntity } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function EntityList() {
  useTitle("Entities List");

  const [entities, setEntities] = useState<IEntity[]>([]);
  const [deleteEntity, setDeleteEntity] = useState<IEntity | null>(null);

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest();

  const columns: TableProps<IEntity>["columns"] = useMemo(
    () => [
      {
        key: "id",
        title: "ID",
        dataIndex: "id",
      },
      {
        key: "name",
        title: "Name",
        dataIndex: "name",
      },
      {
        key: "fantasy_name",
        title: "Fantasy Name",
        dataIndex: "fantasy_name",
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, e) => (
          <div>
            {e.isAdmin && (
              <Button
                className="text-xs mr-2"
                icon={<MdDeleteOutline onClick={() => setDeleteEntity(e)} />}
              />
            )}
          </div>
        ),
      },
    ],
    []
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "entities/api/delete/",
      () => {
        setEntities((old) => old.filter((e) => e.id !== deleteEntity?.id));
        setDeleteEntity(null);
        dispatch(
          setAlert({
            type: "success",
            message: "ENTITY",
            description: "Entity deleted successfully!",
          })
        );
      },
      {
        email: user?.email,
        entityId: deleteEntity?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "entities/api/list/",
      (res) => {
        setEntities(res.data.entity_list);
      },
      {
        email: user?.email,
        list_type: "private",
        pageRequested: 1,
      }
    );
  }, [user?.email, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteEntity}
        title="Delete Entity"
        onCancel={() => setDeleteEntity(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete entity '{deleteEntity?.name}'?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <IoMdBusiness className="mr-2" /> Entities
          </Typography.Title>
          {entities.length === 0 && (
            <Button
              className="text-xs"
              icon={<MdAdd />}
              onClick={() => navigate("/provider/entities/new")}
            >
              Add
            </Button>
          )}
        </div>
        <Table columns={columns} dataSource={entities} pagination={false} />
        <ul className="my-4" style={{ fontSize: "0.7rem" }}>
          <li>To add a new entity, delete the existing entity.</li>
          <li>
            After adding a new entity, you cannot perform services until the
            entity is reviewed by the admin.
          </li>
        </ul>
      </div>
    </PrivateRoute>
  );
}
