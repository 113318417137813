import { Navigate, Route, Routes } from "react-router-dom";
import Profile from "./Profile";
import ResetPassword from "./ResetPassword";
import Settings from "./Settings";
import SocialNetworks from "./SocialNetworks";
import Validator from "./Validator";
import AddressLayout from "./addresses/Layout";
import EmailLayout from "./emails/Layout";
import PhoneLayout from "./phones/Layout";

export default function Layout() {
  return (
    <Routes>
      <Route path="/" element={<Validator />}>
        <Route path="settings" element={<Settings />} />
        <Route path="profile" element={<Profile />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="social-networks" element={<SocialNetworks />} />
        <Route path="phones/*" element={<PhoneLayout />} />
        <Route path="emails/*" element={<EmailLayout />} />
        <Route path="addresses/*" element={<AddressLayout />} />
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Route>
    </Routes>
  );
}
