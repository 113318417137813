import { Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FaFacebook } from "react-icons/fa";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { IAccount } from "../../lib/model";

export default function SocialNetworks() {
  const [accounts, setAccounts] = useState<IAccount[]>([]);

  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IAccount>["columns"] = useMemo(
    () => [
      {
        key: "email",
        title: "Email",
        dataIndex: "email",
      },
      {
        key: "type",
        title: "Type",
        dataIndex: "type",
      },
      {
        key: "created",
        title: "Created",
        dataIndex: "created",
        render: (v) => <span>{new Date(v).toLocaleDateString()}</span>,
      },
    ],
    []
  );

  useEffect(() => {
    listRequest(
      "post",
      "users/api/webaccounts/",
      (res) => {
        setAccounts(res.data.accounts);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <FaFacebook className="mr-2" /> Social Networks
          </Typography.Title>
        </div>
        <Table columns={columns} dataSource={accounts} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
