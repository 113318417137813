import { Button, Modal, Spin, Table, TableProps, Tag, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoPhonePortraitOutline } from "react-icons/io5";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IPhone } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function PhoneList() {
  useTitle("Phone List");

  const [phones, setPhones] = useState<IPhone[]>([]);

  const [deletePhone, setDeletePhone] = useState<IPhone | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IPhone>["columns"] = useMemo(
    () => [
      {
        key: "phone_name",
        title: "Name",
        dataIndex: "phone_name",
      },
      {
        key: "phone_number",
        title: "Number",
        dataIndex: "phone_number",
      },
      {
        key: "primary",
        title: "Primary",
        dataIndex: "primary",
        render: (_, phone: any) =>
          phone.primary ? (
            <Tag color="green" style={{ fontSize: "0.5rem" }}>
              <FaCheck />
            </Tag>
          ) : null,
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, phone: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeletePhone(phone)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/customer/phones/${phone.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "phones/api/webdelete/",
      () => {
        setPhones((old) => old.filter((p) => p.id !== deletePhone?.id));
        setDeletePhone(null);
        dispatch(
          setAlert({
            type: "success",
            message: "PHONE",
            description: "Phone deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        phone_id: deletePhone?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "phones/api/weblist/",
      (res) => {
        setPhones(res.data.phones);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deletePhone}
        title="Delete Phone"
        onCancel={() => setDeletePhone(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete phone '{deletePhone?.phone_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <IoPhonePortraitOutline className="mr-2" /> Phones
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/customer/phones/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={phones} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
