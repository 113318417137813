import { Badge, Button, Descriptions, List, Modal, Spin, Tag } from "antd";
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { FaEye } from "react-icons/fa";
import { IoGitNetwork } from "react-icons/io5";
import { RxCross2 } from "react-icons/rx";
import { TiTick } from "react-icons/ti";
import ReactTimeago from "react-timeago";
import { useRequest } from "../../hooks/useRequest";
import { db } from "../../lib/firebase";
import { IWorkflow, WorkflowState } from "../../lib/model";
import { getDomain, getWorkflowTitle } from "../../lib/utils";
import { useCTX } from "../../state";
import WorkflowChat from "./WorkflowChat";

export default function Workflow() {
  const [workflows, setWorkflows] = useState<IWorkflow[]>([]);
  const [workflow, setWorkflow] = useState<IWorkflow | null>(null);

  const [request, loading] = useRequest(true);
  const {
    state: { user },
  } = useCTX();

  const unsubRef = useRef<{ [k: number]: Unsubscribe }>({});

  useEffect(() => {
    request(
      "post",
      "workflows/api/weblist/",
      async (res) => {
        setWorkflows(
          res.data.workflows.map((w: any) => ({ ...w, pending: 0 }))
        );
      },
      {
        token: localStorage.getItem("token"),
        type: "A",
      }
    );
  }, [request]);

  useEffect(() => {
    if (workflows.length > 0 && Object.keys(unsubRef.current).length === 0) {
      for (const widx in workflows) {
        if (workflows[widx].state !== WorkflowState.COMPLETED) {
          const cref = collection(
            db,
            "workflows",
            `${workflows[widx].id}_workflow@${getDomain()}`,
            "messages"
          );

          let q = query(
            cref,
            where("receiverMail", "==", user?.email),
            where("read", "==", false),
            orderBy("timestamp", "asc")
          );

          unsubRef.current[workflows[widx].id] = onSnapshot(q, (snapshot) => {
            setWorkflows((old) => {
              const result = [...old];
              result[widx] = { ...result[widx], pending: snapshot.docs.length };
              return result;
            });
          });
        }
      }
    }
  }, [workflows, user?.email]);

  if (loading) {
    return (
      <div className="flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <>
      <Modal
        open={!!workflow}
        title={workflow ? getWorkflowTitle(workflow.state) : ""}
        footer={null}
        bodyStyle={{ height: 450 }}
        onCancel={() => setWorkflow(null)}
      >
        {workflow && <WorkflowChat workflow={workflow} />}
      </Modal>
      <List
        className="px-8"
        itemLayout="vertical"
        dataSource={workflows}
        renderItem={(item) => (
          <List.Item
            extra={
              <Badge count={item.pending}>
                <Button
                  icon={<FaEye />}
                  className="ml-8"
                  onClick={() => setWorkflow(item)}
                />
              </Badge>
            }
          >
            <List.Item.Meta
              avatar={<IoGitNetwork />}
              title={getWorkflowTitle(item.type)}
              description={
                <Descriptions>
                  <Descriptions.Item label="status">
                    <Tag
                      color={
                        item.state === WorkflowState.COMPLETED
                          ? "blue"
                          : "yellow"
                      }
                    >
                      {item.state === WorkflowState.COMPLETED
                        ? "completed"
                        : "pending"}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="approved">
                    <Tag color={item.approved ? "green" : "red"}>
                      {item.approved ? <TiTick /> : <RxCross2 />}
                    </Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="created">
                    <ReactTimeago date={item.created_at} />
                  </Descriptions.Item>
                  <Descriptions.Item label="last updated">
                    <ReactTimeago date={item.updated_at} />
                  </Descriptions.Item>
                </Descriptions>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}
