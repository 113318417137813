import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Radio,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { AiOutlinePlus, AiOutlineSave, AiOutlineUser } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useImagePreview } from "../../hooks/useImagePreview";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { MDocumentType, MGender } from "../../lib/model";
import { getBase64 } from "../../lib/utils";
import { useCTX } from "../../state";
import { setAlert, setUser } from "../../state/actionCreators";

type TInfo = {
  gender: string;
  doc_type: string;
  doc_photo: UploadFile[];
  photo_main: UploadFile[];
};

export default function Profile() {
  useTitle("Profile");

  const {
    state: { user },
    dispatch,
  } = useCTX();

  const [info, setInfo] = useState<TInfo>({
    gender: user?.gender || "M",
    doc_type: user?.doc_type || "",
    doc_photo: user?.doc_photo
      ? [
          {
            name: user.doc_photo.split("/").slice(-1)[0],
            uid: user.doc_photo,
            url: user.doc_photo,
            thumbUrl: user.doc_photo,
          },
        ]
      : [],
    photo_main: user?.avatar
      ? [
          {
            name: user.avatar.split("/").slice(-1)[0],
            uid: user.avatar,
            url: user.avatar,
            thumbUrl: user.avatar,
          },
        ]
      : [],
  });

  const navigate = useNavigate();
  const { Preview, onPreview } = useImagePreview();
  const [request, loading] = useRequest();

  const onSave = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("first_name", values.first_name);
    _info.append("middle_name", values.middle_name);
    _info.append("last_name", values.last_name);
    _info.append("last_name_mother", values.last_name_mother);
    _info.append("last_name_father", values.last_name_father);
    _info.append("name", values.name);
    _info.append("gender", info.gender);
    if (values.dtob) {
      _info.append("dtob", values.dtob.format("YYYY-MM-DD"));
    }
    _info.append("cob", values.cob);
    _info.append("tax_id", values.tax_id);
    _info.append("doc_issuer", values.doc_issuer);
    _info.append("doc_id", values.doc_id);
    _info.append("doc_type", info.doc_type);

    if (
      info.photo_main.length > 0 &&
      info.photo_main[0].thumbUrl !== user?.avatar
    ) {
      _info.append("photo_main", info.photo_main[0].originFileObj!);
      _info.append("photo_main_remove", "true");
    } else if (info.photo_main.length === 0 && user?.avatar) {
      _info.append("photo_main_remove", "true");
    }

    if (
      info.doc_photo.length > 0 &&
      info.doc_photo[0].thumbUrl !== user?.doc_photo
    ) {
      _info.append("doc_photo", info.doc_photo[0].originFileObj!);
      _info.append("doc_photo_remove", "true");
    } else if (info.doc_photo.length === 0 && user?.avatar) {
      _info.append("doc_photo_remove", "true");
    }

    request(
      "post",
      "users/api/webprofile/",
      () => {
        dispatch(
          setUser({
            ...user!,
            ...Object.fromEntries(_info),
            avatar:
              info.photo_main.length > 0 ? info.photo_main[0].thumbUrl! : "",
            doc_photo:
              info.doc_photo.length > 0 ? info.doc_photo[0].thumbUrl! : "",
          })
        );
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "PROFILE",
            description: "Profile updated successfully!",
          })
        );
      },
      _info
    );
  };

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center w-full pb-4"
        >
          <AiOutlineUser className="mr-2" /> Profile
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          className="w-full"
          initialValues={{
            first_name: user?.first_name,
            last_name: user?.last_name,
            middle_name: user?.middle_name,
            last_name_mother: user?.last_name_mother,
            last_name_father: user?.last_name_father,
            name: user?.name,
            dtob: user?.dtob ? dayjs(user?.dtob) : undefined,
            cob: user?.cob,
            gender: info.gender,
            tax_id: user?.tax_id,
            doc_issuer: user?.doc_issuer,
            doc_id: user?.doc_id,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Tax ID"
            name="tax_id"
            rules={[{ required: true, message: "Please input your taxid!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item label="Photo" name="photo_main">
            <Upload
              listType="picture-card"
              fileList={info.photo_main}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, photo_main: fileList })
              }
            >
              {info.photo_main.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label="First Name" name="first_name">
            <Input />
          </Form.Item>
          <Form.Item label="Middle Name" name="middle_name">
            <Input />
          </Form.Item>
          <Form.Item label="Last Name" name="last_name">
            <Input />
          </Form.Item>
          <Form.Item label="Mother's Family Name" name="last_name_mother">
            <Input />
          </Form.Item>
          <Form.Item label="Father's Family Name" name="last_name_father">
            <Input />
          </Form.Item>
          <Form.Item label="First & Last Name" name="name">
            <Input />
          </Form.Item>
          <Form.Item label="Date Of Birth" name="dtob">
            <DatePicker
              disabledDate={(current) =>
                current && current > dayjs().subtract(18, "year")
              }
            />
          </Form.Item>
          <Form.Item label="Birth Country" name="cob">
            <Input />
          </Form.Item>
          <Form.Item label="Gender" name="gender">
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => setInfo({ ...info, gender: e.target.value })}
            >
              {MGender.keys.map((v) => (
                <Radio.Button key={v} value={MGender.getValue(v)}>
                  {v}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label="Document Type" name="doc_type">
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MDocumentType.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      doc_type: MDocumentType.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.doc_type
                  ? MDocumentType.getKey(info.doc_type)
                  : "Select the type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Issuing Entity" name="doc_issuer">
            <Input />
          </Form.Item>
          <Form.Item label="Document Number" name="doc_id">
            <Input />
          </Form.Item>
          <Form.Item label="Document (Photo)" name="doc_photo">
            <Upload
              listType="picture-card"
              onPreview={onPreview}
              previewFile={getBase64 as any}
              fileList={info.doc_photo}
              beforeUpload={() => false}
              onChange={({ fileList }) =>
                setInfo({
                  ...info,
                  doc_photo: fileList,
                })
              }
            >
              {info.doc_photo.length < 1 && (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<AiOutlineSave size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PrivateRoute>
  );
}
