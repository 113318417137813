import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { caxios } from "../../../lib/constants";
import { IAccount, IBank, MAccountType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  bank: IBank | null;
  account_type: string;
  primary: boolean;
};

export default function AccountEdit() {
  useTitle("Edit Account");

  const [loading, setLoading] = useState(true);
  const [banks, setBanks] = useState<IBank[]>([]);
  const [account, setAccount] = useState<IAccount>();
  const [info, setInfo] = useState<TInfo>({
    bank: null,
    account_type: "1",
    primary: false,
  });

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useCTX();
  const [updateRequest, updating] = useRequest();

  const onSave = (values: any) => {
    const _info = {
      ...values,
      ...info,
      email: user?.email,
      bankId: info.bank?.id,
      baccId: params.id,
    };

    updateRequest(
      "post",
      "bankaccounts/api/addUpdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "BANK ACCOUNT",
            description: "Account created successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    Promise.all([
      caxios.post("banks/api/list/", {
        email: user?.email,
        pageRequested: 1,
      }),
      caxios.post("bankaccounts/api/detail/", {
        email: user?.email,
        baccId: params.id,
      }),
    ])
      .then((resps) => {
        setBanks(resps[0].data.bank_list);
        const b = resps[1].data.bkacc;
        setAccount(b);
        setInfo({
          bank: b.bank,
          account_type: b.account_type,
          primary: b.primary,
        });
      })
      .finally(() => setLoading(false));
  }, [user?.email, params.id, dispatch]);

  useEffect(() => {
    if (!loading && !account) {
      navigate(-1);
    }
  }, [loading, account, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!account) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Bank Account
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            account_name: account.account_name,
            account_owner: account.account_owner,
            account_owner_ID: account.account_owner_ID,
            account_branch: account.account_branch,
            account_branch_vc: account.account_branch_vc,
            account_number: account.account_number,
            account_number_vc: account.account_number_vc,
            IFSC_Code: account.IFSC_Code,
            GST_number: account.GST_number,
            PAN_number: account.PAN_number,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Name"
            name="account_name"
            rules={[{ required: true, message: "Please input account name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Bank"
            name="bank"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.bank)
                    return Promise.reject("Please select a bank!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: banks.map((b) => ({
                  key: b.bank_name,
                  label: b.bank_name,
                  onClick: () =>
                    setInfo({
                      ...info,
                      bank: b,
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.bank ? info.bank.bank_name : "Select a Bank"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Type" name="account_type" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MAccountType.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      account_type: MAccountType.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.account_type
                  ? MAccountType.getKey(info.account_type)
                  : "Select the Type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Owner Name"
            name="account_owner"
            rules={[
              { required: true, message: "Please input account owner name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tax ID: CPF"
            name="account_owner_ID"
            rules={[
              { required: true, message: "Please input account owner ID!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Branch Number"
            name="account_branch"
            rules={[
              {
                required: true,
                message: "Please input account branch number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Branch VC" name="account_branch_vc">
            <Input />
          </Form.Item>
          <Form.Item
            label="Account Number"
            name="account_number"
            rules={[
              { required: true, message: "Please input account number!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Account VC" name="account_number_vc">
            <Input />
          </Form.Item>
          {info.bank?.country === "IN" && (
            <>
              <Form.Item
                label="IFSC Code"
                name="IFSC_Code"
                rules={[
                  {
                    required: true,
                    message: "Please input account IFSC code!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="GST Number"
                name="GST_number"
                rules={[
                  { required: true, message: "Please input GST number!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="PAN Number"
                name="PAN_number"
                rules={[
                  { required: true, message: "Please input PAN number!" },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          <Checkbox
            onChange={(e) => setInfo({ ...info, primary: e.target.checked })}
            checked={info.primary}
            className="mb-8"
          >
            primary
          </Checkbox>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
