import {
  Button,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { caxios } from "../../../lib/constants";
import { IPhoto, IService } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  photo: UploadFile[];
  service: IService | null;
};

export default function GalleryEdit() {
  useTitle("Edit Photo");

  const [loading, setLoading] = useState(true);
  const [photo, setPhoto] = useState<IPhoto | null>(null);
  const [services, setServices] = useState<IService[]>([]);
  const [info, setInfo] = useState<TInfo>({ photo: [], service: null });

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useCTX();
  const { Preview, onPreview } = useImagePreview();
  const [updateRequest, updating] = useRequest();

  const onSave = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("photo_id", `${params.id}`);
    _info.append("operation", "U");
    _info.append("title", values.title);
    _info.append("descr", values.descr);
    _info.append("customer_email", values.customer_email || "");

    if (info.service) {
      _info.append("service_id", `${info.service.service_id}`);
    }

    if (info.photo[0].thumbUrl?.includes("data:")) {
      _info.append("photo", info.photo[0].originFileObj!);
    }

    updateRequest(
      "post",
      "photos/api/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "PHOTO",
            description: "Photo updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    Promise.all([
      caxios.post("services/api/servicesSearch/", {
        email: user?.email,
        pageRequested: 1,
      }),
      caxios.post("photos/api/webdetail/", {
        token: localStorage.getItem("token"),
        photo_id: params.id,
      }),
    ])
      .then((resps) => {
        setServices(resps[0].data.services_list);
        const p = resps[1].data.photo;
        setPhoto(p);
        setInfo({
          photo: p.photo
            ? [
                {
                  name: p.photo.split("/").slice(-1)[0],
                  uid: p.photo,
                  url: p.photo,
                  thumbUrl: p.photo,
                },
              ]
            : [],
          service: p.service
            ? resps[0].data.services_list.find(
                (s: any) => s.service_name === p.service
              )
            : null,
        });
      })
      .finally(() => setLoading(false));
  }, [params.id, user?.email]);

  useEffect(() => {
    if (!loading && !photo) {
      navigate(-1);
    }
  }, [loading, photo, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!photo) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <Preview />
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Photo
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            title: photo?.title,
            descr: photo?.descr,
            customer_email: photo?.customer_email,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Photo"
            name="photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo.length === 0)
                    return Promise.reject("Please select a photo!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) => setInfo({ ...info, photo: fileList })}
            >
              {info.photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[{ required: true, message: "Please input photo title!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="descr" required>
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item label="Service" name="service">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: services.map((s) => ({
                  key: s.service_name,
                  label: s.service_name,
                  onClick: () =>
                    setInfo({
                      ...info,
                      service: s,
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.service ? info.service.service_name : "Select a Service"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Customer" name="customer_email">
            <Input />
          </Form.Item>
          <Form.Item className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </PrivateRoute>
  );
}
