import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import {
  IOffering,
  MPriceType,
  MVariableDistanceUnit,
  MVariableTimeUnit,
  MVariableUnit,
} from "../../../lib/model";
import { formatDuration, getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  offer_photo: UploadFile[];
};

export default function OfferingEdit() {
  useTitle("Edit Offering");

  const [offering, setOffering] = useState<IOffering | null>(null);
  const [info, setInfo] = useState<TInfo>({
    offer_photo: [],
  });

  const params = useParams();
  const navigate = useNavigate();
  const {
    state: { user },
    dispatch,
  } = useCTX();
  const { Preview, onPreview } = useImagePreview();

  const [updateRequest, updating] = useRequest();
  const [detailRequest, loading] = useRequest(true);

  const [form] = Form.useForm();

  const onSave = (values: any) => {
    const _info = new FormData();
    _info.append("email", user!.email);
    _info.append("operation", "U");
    _info.append("offeringId", `${params.id}`);
    _info.append("offer_descr", values.offer_descr);
    _info.append("price", values.price);
    _info.append("var_time_price_unit", `${values.var_time_price_unit || 0}`);
    _info.append(
      "var_distance_price_unit",
      `${values.var_distance_price_unit || 0}`
    );
    _info.append("var_time_min_unit", `${values.var_time_min_unit || 0}`);
    _info.append(
      "var_distance_min_unit",
      `${values.var_distance_min_unit || 0}`
    );
    _info.append("exec_time_days", values.exec_time_days);
    _info.append("exec_time_hours", values.exec_time_hours);
    _info.append("exec_time_mins", values.exec_time_mins);
    _info.append("exec_time", values.exec_time);
    _info.append(
      "exec_time",
      `${values.exec_time_days}:${values.exec_time_hours}:${values.exec_time_mins}`
    );
    _info.append("tcs", values.tcs);

    if (
      info.offer_photo.length > 0 &&
      info.offer_photo[0].thumbUrl?.includes("data:")
    ) {
      _info.append("offer_photo", info.offer_photo[0].originFileObj!);
    }

    updateRequest(
      "post",
      "providers/api/providerOfferingUpdate",

      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "OFFERING",
            description: "Offering updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    detailRequest(
      "post",
      "providers/api/offeringDetail/",
      (res) => {
        const o = res.data.offering;
        setOffering(o);
        setInfo({
          offer_photo: o.offer_photo
            ? [
                {
                  name: o.offer_photo.split("/").slice(-1)[0],
                  uid: o.offer_photo,
                  url: o.offer_photo,
                  thumbUrl: o.offer_photo,
                },
              ]
            : [],
        });
      },
      {
        email: user?.email,
        offeringId: params.id,
      }
    );
  }, [params.id, detailRequest, user?.email]);

  useEffect(() => {
    if (!loading && !offering) {
      navigate(-1);
    }
  }, [loading, offering, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!offering) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <Preview />
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Offering
        </Typography.Title>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            offer_name: offering.offer_name,
            offer_descr: offering.offer_descr,
            offer_start_date: offering.offer_start_date
              ? dayjs(offering.offer_start_date)
              : undefined,
            price_type: MPriceType.getKey(offering.price_type),
            price: offering.price,
            variable_unit: MVariableUnit.getKey(offering.variable_unit),
            var_time_unit: MVariableTimeUnit.getKey(offering.var_time_unit),
            var_distance_unit: MVariableDistanceUnit.getKey(
              offering.var_distance_unit
            ),
            var_time_price_unit: offering.var_time_price_unit,
            var_distance_price_unit: offering.var_distance_price_unit,
            var_time_min_unit: offering.var_time_min_unit,
            var_distance_min_unit: offering.var_distance_min_unit,
            exec_time_days: offering.exec_time_days,
            exec_time_hours: offering.exec_time_hours,
            exec_time_mins: offering.exec_time_mins,
            exec_time: formatDuration(
              offering.exec_time_days,
              offering.exec_time_hours,
              offering.exec_time_mins
            ),
            tcs: offering.tcs,
          }}
          onFinish={onSave}
          onValuesChange={(_, v) => {
            form.setFieldValue(
              "exec_time",
              formatDuration(
                v.exec_time_days || 0,
                v.exec_time_hours || 0,
                v.exec_time_mins || 0
              )
            );
          }}
        >
          <Form.Item label="Name" name="offer_name" required>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Photo"
            name="offer_photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.offer_photo.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.offer_photo}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, offer_photo: fileList })
              }
            >
              {info.offer_photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Description"
            name="offer_descr"
            rules={[
              {
                required: true,
                message: "Please input offering description!",
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="offer_start_date"
            rules={[
              {
                required: true,
                message: "Please provide a start date!",
              },
            ]}
          >
            <DatePicker disabled />
          </Form.Item>
          <Form.Item label="Price Type" name="price_type" required>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Price"
            name="price"
            rules={[
              { required: true, message: "Please input offering price!" },
            ]}
          >
            <InputNumber disabled={offering.price_type === "V"} />
          </Form.Item>
          {offering.price_type === "V" && (
            <Fragment>
              <Form.Item label="Variable Unit" name="variable_unit" required>
                <Input disabled />
              </Form.Item>
              {offering.variable_unit === "T" ? (
                <Fragment>
                  <Form.Item
                    label="Variable Time Unit"
                    name="var_time_unit"
                    required
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Variable Time Price Unit"
                    name="var_time_price_unit"
                    rules={[
                      {
                        required: true,
                        message: "Please input variable time price unit!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="Variable Time Minimum Unit"
                    name="var_time_min_unit"
                    rules={[
                      {
                        required: true,
                        message: "Please input variable time minimum unit!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Fragment>
              ) : (
                <Fragment>
                  <Form.Item
                    label="Variable Distance Unit"
                    name="var_distance_unit"
                    required
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Variable Distance Price Unit"
                    name="var_distance_price_unit"
                    rules={[
                      {
                        required: true,
                        message: "Please input variable distance price unit!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item
                    label="Variable Distance Minimum Unit"
                    name="var_distance_min_unit"
                    rules={[
                      {
                        required: true,
                        message: "Please input variable distance minimum unit!",
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Fragment>
              )}
            </Fragment>
          )}
          <Form.Item
            label="Execution Time (Days)"
            name="exec_time_days"
            rules={[
              {
                required: true,
                message: "Please input execution time (days)!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Execution Time (Hours)"
            name="exec_time_hours"
            rules={[
              {
                required: true,
                message: "Please input execution time (hours)!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Execution Time (Minutes)"
            name="exec_time_mins"
            rules={[
              {
                required: true,
                message: "Please input execution time (mins)!",
              },
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label="Execution Time"
            name="exec_time"
            rules={[
              {
                required: true,
                message: "Please input execution time!",
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Terms & Conditions"
            name="tcs"
            rules={[
              {
                required: true,
                message: "Please input terms & conditions!",
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
