import { Navigate, Route, Routes } from "react-router-dom";
import AddressEdit from "./Edit";
import AddressList from "./List";
import AddressNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<AddressList />} />
      <Route path="new" element={<AddressNew />} />
      <Route path=":id/edit" element={<AddressEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
