import {
  Button,
  Checkbox,
  Modal,
  Spin,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { FaCheck } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IEmail } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  primary: boolean;
};

export default function EmailList() {
  useTitle("Email List");

  const [info, setInfo] = useState<TInfo>({ primary: false });
  const [emails, setEmails] = useState<IEmail[]>([]);
  const [deleteEmail, setDeleteEmail] = useState<IEmail | null>(null);
  const [editEmail, setEditEmail] = useState<IEmail | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [updateRequest, updating] = useRequest();
  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IEmail>["columns"] = useMemo(
    () => [
      {
        key: "email",
        title: "Email",
        dataIndex: "email",
      },
      {
        key: "verified",
        title: "Verified",
        dataIndex: "verified",
        render: (_, email: any) =>
          email.verified ? (
            <Tag color="green" style={{ fontSize: "0.5rem" }}>
              <FaCheck />
            </Tag>
          ) : (
            <Tag color="red" style={{ fontSize: "0.5rem" }}>
              <AiOutlineClose />
            </Tag>
          ),
      },
      {
        key: "primary",
        title: "Primary",
        dataIndex: "primary",
        render: (_, email: any) =>
          email.primary ? (
            <Tag color="green" style={{ fontSize: "0.5rem" }}>
              <FaCheck />
            </Tag>
          ) : null,
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, email: any) => (
          <div>
            {!email.primary && (
              <>
                <Button
                  className="text-xs mr-2"
                  icon={<MdDeleteOutline />}
                  onClick={() => setDeleteEmail(email)}
                />
                <Button
                  className="text-xs"
                  icon={<MdEdit />}
                  onClick={() => {
                    setEditEmail(email);
                    setInfo({ primary: email.primary });
                  }}
                />
              </>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const onUpdate = (operation: "P" | "V") => {
    updateRequest(
      "post",
      "users/api/webmailupdate/",
      () => {
        if (operation === "P") {
          setEmails((old) => {
            const eidx = old.findIndex((e) => e.id === editEmail?.id);
            const peidx = old.findIndex((e) => !!e.primary);
            old[peidx] = { ...old[peidx], primary: false };
            old[eidx] = { ...old[eidx], primary: true };
            return [...old];
          });
          dispatch(
            setAlert({
              type: "success",
              message: "EMAIL",
              description: "Email updated successfully!",
            })
          );
        } else {
          dispatch(
            setAlert({
              type: "success",
              message: "EMAIL",
              description: `Verification email has been sent to '${editEmail?.email}'!`,
            })
          );
        }
        setEditEmail(null);
      },
      {
        token: localStorage.getItem("token"),
        email_id: editEmail?.id,
        operation,
      }
    );
  };

  const onDelete = () => {
    deleteRequest(
      "post",
      "users/api/webmaildelete/",
      () => {
        setEmails((old) => old.filter((e) => e.id !== deleteEmail?.id));
        setDeleteEmail(null);
        dispatch(
          setAlert({
            type: "success",
            message: "EMAIL",
            description: "Email deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        email_id: deleteEmail?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "users/api/webmaillist/",
      (res) => {
        setEmails(res.data.emails);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteEmail}
        title="Delete Email"
        onCancel={() => setDeleteEmail(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete email '{deleteEmail?.email}
          '?
        </span>
      </Modal>
      <Modal
        open={!!editEmail}
        title="Edit Email"
        onCancel={() => setEditEmail(null)}
        okText="Save"
        okButtonProps={{ disabled: info.primary === editEmail?.primary }}
        confirmLoading={updating}
        onOk={() => onUpdate("P")}
      >
        <div className="flex flex-col my-8">
          <span className="mb-2 font-bold">{editEmail?.email}</span>
          <Checkbox
            onChange={(e) => setInfo({ ...info, primary: e.target.checked })}
            checked={info.primary}
            disabled={!editEmail?.verified}
            className="mt-2"
          >
            primary
          </Checkbox>
          {!editEmail?.verified && (
            <span style={{ fontSize: "0.7rem" }}>
              * email must be verified to be primary.
            </span>
          )}
        </div>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <IoMailOutline className="mr-2" /> Emails
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/customer/emails/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={emails} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
