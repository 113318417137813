import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyD2eUcEY2RhkIsasQFpRcQJ4naDX9K0RHo",
  authDomain: "yunee-c772a.firebaseapp.com",
  databaseURL: "https://yunee-c772a.firebaseio.com",
  projectId: "yunee-c772a",
  storageBucket: "yunee-c772a.appspot.com",
  messagingSenderId: "1048656491626",
  appId: "1:1048656491626:web:075c91996cc531eaf60613",
  measurementId: "G-Q7XEZGN7D0",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { db, storage };
