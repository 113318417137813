import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { Fragment, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { MdLocalOffer } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import {
  IService,
  MPriceType,
  MVariableDistanceUnit,
  MVariableTimeUnit,
  MVariableUnit,
} from "../../../lib/model";
import { formatDuration, getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  offer_photo: UploadFile[];
  service: IService | null;
};

export default function OfferingNew() {
  useTitle("New Offering");

  const [services, setServices] = useState<IService[]>([]);
  const [info, setInfo] = useState<TInfo>({
    offer_photo: [],
    service: null,
  });

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { Preview, onPreview } = useImagePreview();
  const [form] = Form.useForm();

  const [updateRequest, updating] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onAdd = (values: any) => {
    const _info = new FormData();
    _info.append("email", user!.email);
    _info.append("operation", "A");
    _info.append("serviceId", `${info.service?.service_id}`);
    _info.append(
      "offer_start_date",
      values.offer_start_date.format("YYYY-MM-DD")
    );
    _info.append("offer_descr", values.offer_descr);
    _info.append("offer_photo", info.offer_photo[0].originFileObj!);
    _info.append("price", values.price);
    _info.append("var_time_price_unit", `${values.var_time_price_unit || 0}`);
    _info.append(
      "var_distance_price_unit",
      `${values.var_distance_price_unit || 0}`
    );
    _info.append("var_time_min_unit", `${values.var_time_min_unit || 0}`);
    _info.append(
      "var_distance_min_unit",
      `${values.var_distance_min_unit || 0}`
    );
    _info.append("exec_time_days", values.exec_time_days);
    _info.append("exec_time_hours", values.exec_time_hours);
    _info.append("exec_time_mins", values.exec_time_mins);
    _info.append("exec_time", values.exec_time);
    _info.append(
      "exec_time",
      `${values.exec_time_days}:${values.exec_time_hours}:${values.exec_time_mins}`
    );
    _info.append("tcs", values.tcs);

    updateRequest(
      "post",
      "providers/api/providerOfferingUpdate",

      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "OFFERING",
            description:
              "Offering created successfully, please wait patiently for approval!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "services/api/servicesSearch/",
      (res) => {
        setServices(res.data.services_list);
        if (state?.service_id) {
          setInfo((old) => ({
            ...old,
            service: res.data.services_list.find(
              (s: any) => s.service_id === state.service_id
            ),
          }));
        }
      },
      {
        email: user?.email,
        providerId: user?.id,
        pageRequested: 1,
      }
    );
  }, [user, listRequest, state]);

  useEffect(() => {
    if (info.service) {
      form.setFieldsValue({
        price_type: MPriceType.getKey(info.service.service_price_type),
        variable_unit: MVariableUnit.getKey(info.service.service_variable_unit),
        price: info.service.service_price,
        offer_name: info.service.service_name,
        offer_descr: info.service.service_long_descr,
        var_time_unit: MVariableTimeUnit.getKey(
          info.service.service_var_time_unit
        ),
        var_time_price_unit: info.service.service_var_time_price_unit,
        var_time_min_unit: info.service.service_var_time_min_unit,
        var_distance_unit: MVariableDistanceUnit.getKey(
          info.service.service_var_distance_unit
        ),
        var_distance_price_unit: info.service.service_var_distance_price_unit,
        var_distance_min_unit: info.service.service_var_distance_min_unit,
        exec_time_days: info.service.service_exec_time_days,
        exec_time_hours: info.service.service_exec_time_hours,
        exec_time_mins: info.service.service_exec_time_mins,
        exec_time: formatDuration(
          info.service.service_exec_time_days,
          info.service.service_exec_time_hours,
          info.service.service_exec_time_mins
        ),
        tcs: info.service.service_tcs,
      });
    }
  }, [form, info.service]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdLocalOffer className="mr-2" /> Offering
        </Typography.Title>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          labelAlign="left"
          onFinish={onAdd}
          onValuesChange={(_, v) => {
            form.setFieldValue(
              "exec_time",
              formatDuration(
                v.exec_time_days || 0,
                v.exec_time_hours || 0,
                v.exec_time_mins || 0
              )
            );
          }}
        >
          <Form.Item
            label="Service"
            name="service"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.service)
                    return Promise.reject("Please select a service!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: services.map((s) => ({
                  key: s.service_name,
                  label: s.service_name,
                  onClick: () =>
                    setInfo({
                      ...info,
                      service: s,
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.service ? info.service.service_name : "Select a Service"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Name" name="offer_name" required>
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Photo"
            name="offer_photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.offer_photo.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.offer_photo}
              onPreview={onPreview}
              previewFile={getBase64 as any}
              beforeUpload={() => false}
              onChange={({ fileList }) =>
                setInfo({ ...info, offer_photo: fileList })
              }
            >
              {info.offer_photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Description"
            name="offer_descr"
            rules={[
              {
                required: true,
                message: "Please input offering description!",
              },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item
            label="Start Date"
            name="offer_start_date"
            rules={[
              {
                required: true,
                message: "Please provide a start date!",
              },
            ]}
          >
            <DatePicker disabledDate={(d) => d.isBefore(dayjs(), "date")} />
          </Form.Item>
          {info.service && (
            <Fragment>
              <Form.Item label="Price Type" name="price_type" required>
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  { required: true, message: "Please input offering price!" },
                ]}
              >
                <InputNumber
                  disabled={info.service.service_price_type === "V"}
                />
              </Form.Item>
              {info.service.service_price_type === "V" && (
                <Fragment>
                  <Form.Item
                    label="Variable Unit"
                    name="variable_unit"
                    required
                  >
                    <Input disabled />
                  </Form.Item>
                  {info.service.service_variable_unit === "T" ? (
                    <Fragment>
                      <Form.Item
                        label="Variable Time Unit"
                        name="var_time_unit"
                        required
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        label="Variable Time Price Unit"
                        name="var_time_price_unit"
                        rules={[
                          {
                            required: true,
                            message: "Please input variable time price unit!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        label="Variable Time Minimum Unit"
                        name="var_time_min_unit"
                        rules={[
                          {
                            required: true,
                            message: "Please input variable time minimum unit!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Form.Item
                        label="Variable Distance Unit"
                        name="var_distance_unit"
                        required
                      >
                        <Input disabled />
                      </Form.Item>
                      <Form.Item
                        label="Variable Distance Price Unit"
                        name="var_distance_price_unit"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input variable distance price unit!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                      <Form.Item
                        label="Variable Distance Minimum Unit"
                        name="var_distance_min_unit"
                        rules={[
                          {
                            required: true,
                            message:
                              "Please input variable distance minimum unit!",
                          },
                        ]}
                      >
                        <InputNumber />
                      </Form.Item>
                    </Fragment>
                  )}
                </Fragment>
              )}
              <Form.Item
                label="Execution Time (Days)"
                name="exec_time_days"
                rules={[
                  {
                    required: true,
                    message: "Please input execution time (days)!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Execution Time (Hours)"
                name="exec_time_hours"
                rules={[
                  {
                    required: true,
                    message: "Please input execution time (hours)!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Execution Time (Minutes)"
                name="exec_time_mins"
                rules={[
                  {
                    required: true,
                    message: "Please input execution time (mins)!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Execution Time"
                name="exec_time"
                rules={[
                  {
                    required: true,
                    message: "Please input execution time!",
                  },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Terms & Conditions"
                name="tcs"
                rules={[
                  {
                    required: true,
                    message: "Please input terms & conditions!",
                  },
                ]}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Fragment>
          )}
          <div className="flex justify-end">
            <Button
              type="primary"
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
