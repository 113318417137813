import {
  Avatar,
  Button,
  Dropdown,
  Form,
  Input,
  Radio,
  Select,
  Spin,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { useCallback, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { FiSave } from "react-icons/fi";
import { IoMdDocument } from "react-icons/io";
import { MdInsertPhoto } from "react-icons/md";
import { RiUser3Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import BackButton from "../components/BackButton";
import { PrivateRoute } from "../components/Route";
import { useImagePreview } from "../hooks/useImagePreview";
import { useRequest } from "../hooks/useRequest";
import { useTitle } from "../hooks/useTitle";
import { caxios } from "../lib/constants";
import { ICity, IEntity, MCalendarMode, MLink } from "../lib/model";
import { getBase64 } from "../lib/utils";
import { useCTX } from "../state";
import { setAlert, setUser } from "../state/actionCreators";

type TInfo = {
  entity: IEntity | null;
  photo: UploadFile[];
  cities: number[];
  city: ICity | null;
  calendar: string;
  link: {
    entity_link: string;
    entity_nick: string;
    entity_photo: UploadFile[];
    entity_doc: UploadFile[];
    entity_link_comments: string;
  };
};

export default function BecomeProvider() {
  useTitle("Become Provider");

  const [entities, setEntities] = useState<IEntity[]>([]);
  const [cities, setCities] = useState<ICity[]>([]);
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState<TInfo>({
    entity: null,
    city: null,
    photo: [],
    cities: [],
    calendar: "A",
    link: {
      entity_link: "",
      entity_nick: "",
      entity_photo: [],
      entity_doc: [],
      entity_link_comments: "",
    },
  });

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const [request, submitting] = useRequest();
  const { Preview, onPreview } = useImagePreview();

  const onSubmit = useCallback(
    (values: any) => {
      const _info = new FormData();
      _info.append("token", localStorage.getItem("token")!);
      _info.append("photo", info.photo[0].originFileObj!);
      _info.append("profession", values.profession);
      _info.append("experience", values.experience);
      _info.append("city", info.city!.id.toString());
      _info.append("calendar", info.calendar);
      _info.append("cities", JSON.stringify(info.cities));

      if (info.entity) {
        _info.append("entity", info.entity.id.toString());
        _info.append("entity_link", info.link.entity_link);
        _info.append("entity_nick", info.link.entity_nick);
        if (info.link.entity_photo.length > 0) {
          _info.append(
            "entity_photo",
            info.link.entity_photo[0].originFileObj!
          );
        }
        if (info.link.entity_doc.length > 0) {
          _info.append("entity_doc", info.link.entity_doc[0].originFileObj!);
        }
        _info.append("entity_link_comments", info.link.entity_link_comments);
      }

      request(
        "post",
        "providers/api/becomeProvider/",
        () => {
          navigate("/");
          dispatch(setUser({ ...user!, prov_status: "R" }));
          dispatch(
            setAlert({
              type: "success",
              message: "PROVIDER",
              description: "Application has been submitted successfully.",
            })
          );
        },
        _info
      );
    },
    [user, info, dispatch, navigate, request]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      caxios.post("entities/api/list/", {
        email: user?.email,
        pageRequested: 1,
      }),
      caxios.post("cities/api/list/", {
        email: user?.email,
        pageRequested: 1,
      }),
    ])
      .then((resps) => {
        setEntities(resps[0].data.entity_list);
        setCities(resps[1].data.city_list);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [user?.email, dispatch]);

  if (loading) {
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>;
  }

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title level={4} className="font-poppins flex flex-col pb-4">
          <div className="flex items-center">
            <RiUser3Fill className="mr-2" /> Become Provider
          </div>
          <ul className="my-4" style={{ fontSize: "0.7rem" }}>
            {user?.tax_id ? (
              <li>You can select an entity or leave it blank.</li>
            ) : (
              <li>You must add a taxid in the profile to become a provider.</li>
            )}
          </ul>
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onSubmit}>
          <Form.Item label="Entity" name="entity">
            <Dropdown
              trigger={["click"]}
              menu={{
                items: entities.map((e) => ({
                  key: e.name,
                  label: e.name,
                  icon: (
                    <Avatar
                      src={e?.photo ? e.photo : "/images/covers/empty.png"}
                      className="w-10 h-10"
                    />
                  ),
                  onClick: () => setInfo({ ...info, entity: e }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.entity ? (
                  <div className="flex items-center">
                    <Avatar
                      src={
                        info.entity?.photo
                          ? info.entity.photo
                          : "/images/covers/empty.png"
                      }
                      className="w-8 h-8 mr-2"
                    />
                    <span>{info.entity.name}</span>
                  </div>
                ) : (
                  "Select the Entity"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
            {info.entity && (
              <div className="my-8">
                <Dropdown
                  trigger={["click"]}
                  className="mb-2"
                  menu={{
                    items: MLink.keys.map((v) => ({
                      key: v,
                      label: v,
                      onClick: () =>
                        setInfo({
                          ...info,
                          link: {
                            ...info.link,
                            entity_link: MLink.getValue(v),
                          },
                        }),
                    })),
                  }}
                >
                  <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                    {info.link.entity_link
                      ? MLink.getKey(info.link.entity_link)
                      : "Select relationship to entity"}
                    <BiChevronDown className="ml-2" />
                  </div>
                </Dropdown>
                <Input
                  autoFocus
                  placeholder="Nick"
                  value={info.link.entity_nick}
                  className="mb-2"
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      link: { ...info.link, entity_nick: e.target.value },
                    })
                  }
                />
                <TextArea
                  rows={5}
                  placeholder="Comment"
                  className="mb-2"
                  value={info.link.entity_link_comments}
                  onChange={(e) =>
                    setInfo({
                      ...info,
                      link: {
                        ...info.link,
                        entity_link_comments: e.target.value,
                      },
                    })
                  }
                />
                <div className="flex flex-col">
                  <div className="flex gap-x-2">
                    <Upload
                      beforeUpload={() => false}
                      onChange={({ fileList }) =>
                        setInfo({
                          ...info,
                          link: { ...info.link, entity_photo: fileList },
                        })
                      }
                    >
                      {info.link.entity_photo.length < 1 && (
                        <Button className="text-xs" icon={<MdInsertPhoto />}>
                          Upload Photo
                        </Button>
                      )}
                    </Upload>
                    <Upload
                      beforeUpload={() => false}
                      onChange={({ fileList }) =>
                        setInfo({
                          ...info,
                          link: { ...info.link, entity_doc: fileList },
                        })
                      }
                    >
                      {info.link.entity_doc.length < 1 && (
                        <Button className="text-xs" icon={<IoMdDocument />}>
                          Upload Document
                        </Button>
                      )}
                    </Upload>
                  </div>
                  <ul className="my-2" style={{ fontSize: "0.7rem" }}>
                    <li>Please provide a proof of relationship.</li>
                  </ul>
                </div>
              </div>
            )}
          </Form.Item>
          <Form.Item
            label="Photo"
            name="photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) => setInfo({ ...info, photo: fileList })}
              className="mb-8"
            >
              {info.photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Profession"
            name="profession"
            rules={[
              { required: true, message: "Please input your profession!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Experience"
            name="experience"
            rules={[
              { required: true, message: "Please input your experience!" },
            ]}
          >
            <TextArea rows={5} />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            required
            rules={[
              () => ({
                validator: () => {
                  if (!info.city)
                    return Promise.reject(new Error("Please input your city!"));
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: cities.map((c) => ({
                  key: c.id,
                  label: `${c.name} - ${c.state} - ${c.country}`,
                  onClick: () => setInfo({ ...info, city: c }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.city ? (
                  <div className="flex items-center">
                    <span>{info.city.name}</span>
                  </div>
                ) : (
                  "Select your City"
                )}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Servicing Cities"
            name="cities"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.cities.length === 0)
                    return Promise.reject(
                      new Error("Please add atleast one city!")
                    );
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              allowClear
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select City(s)"
              defaultValue={info.cities}
              onChange={(values) => setInfo({ ...info, cities: values })}
              options={cities.map((c) => ({
                label: `${c.name} - ${c.state} - ${c.country}`,
                value: c.id,
              }))}
            />
          </Form.Item>
          <Form.Item
            label="Calendar"
            name="calendar"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.calendar)
                    return Promise.reject("Please input calendar!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              className="mb-2"
              defaultValue={info.calendar}
              onChange={(e) => setInfo({ ...info, calendar: e.target.value })}
            >
              {MCalendarMode.keys.map((v) => (
                <Radio.Button value={MCalendarMode.getValue(v)}>
                  {v}
                </Radio.Button>
              ))}
            </Radio.Group>
            <div className="text-xs">
              <span>
                This setting determines how client appointments are added to
                your calendar:
              </span>
              <ul>
                <li className="mb-2">
                  <span className="font-bold">Automatic: </span>
                  New appointment requests are automatically accepted and added
                  to your calendar. This is perfect for maximizing bookings and
                  minimizing your admin time.
                </li>
                <li>
                  <span className="font-bold">Semi-Automatic: </span>
                  You receive a notification for each new appointment request
                  and can choose to accept or decline it. This option gives you
                  more control over your schedule.
                </li>
              </ul>
            </div>
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<FiSave size={10} />}
              loading={submitting}
              disabled={!user?.tax_id}
              htmlType="submit"
            >
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
