import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Input,
  Spin,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { AiOutlineBank } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IBank, MAccountType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  bank: IBank | null;
  account_type: string;
  primary: boolean;
};

export default function AccountNew() {
  useTitle("New Account");

  const [banks, setBanks] = useState<IBank[]>([]);
  const [info, setInfo] = useState<TInfo>({
    bank: null,
    account_type: "1",
    primary: false,
  });

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();

  const [updateRequest, updating] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onAdd = (values: any) => {
    const _info = {
      ...values,
      ...info,
      email: user?.email,
      bankId: info.bank?.id,
    };

    updateRequest(
      "post",
      "bankaccounts/api/addUpdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "BANK ACCOUNT",
            description: "Account created successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "banks/api/list/",
      (res) => {
        setBanks(res.data.bank_list);
      },
      {
        email: user?.email,
        pageRequested: 1,
      }
    );
  }, [user, listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <AiOutlineBank className="mr-2" /> Bank Account
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onAdd}>
          <Form.Item
            label="Name"
            name="account_name"
            rules={[{ required: true, message: "Please input account name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Bank"
            name="bank"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.bank)
                    return Promise.reject("Please select a bank!");
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              menu={{
                items: banks.map((b) => ({
                  key: b.bank_name,
                  label: b.bank_name,
                  onClick: () =>
                    setInfo({
                      ...info,
                      bank: b,
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.bank ? info.bank.bank_name : "Select a Bank"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Type" name="account_type" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MAccountType.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      account_type: MAccountType.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.account_type
                  ? MAccountType.getKey(info.account_type)
                  : "Select the Type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Owner Name"
            name="account_owner"
            rules={[
              { required: true, message: "Please input account owner name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tax ID"
            name="account_owner_ID"
            rules={[
              { required: true, message: "Please input account owner ID!" },
              {
                max: 30,
                message: "Tax ID length must be less than or equal to 30!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Branch Number"
            name="account_branch"
            rules={[
              {
                required: true,
                message: "Please input account branch number!",
              },
              {
                max: 10,
                message:
                  "Branch number length must be less than or equal to 10!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Account Number"
            name="account_number"
            rules={[
              { required: true, message: "Please input account number!" },
              {
                max: 15,
                message:
                  "Account number length must be less than or equal to 15!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          {info.bank?.country === "IN" && (
            <>
              <Form.Item
                label="IFSC Code"
                name="IFSC_Code"
                rules={[
                  {
                    required: true,
                    message: "Please input account IFSC code!",
                  },
                  {
                    max: 11,
                    message:
                      "IFSC Code length must be less than or equal to 11!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="GST Number"
                name="GST_number"
                rules={[
                  { required: true, message: "Please input GST number!" },
                  {
                    max: 15,
                    message:
                      "GST number length must be less than or equal to 15!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="PAN Number"
                name="PAN_number"
                rules={[
                  { required: true, message: "Please input PAN number!" },
                  {
                    max: 10,
                    message:
                      "PAN number length must be less than or equal to 10!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </>
          )}
          <Form.Item
            label="Branch VC"
            name="account_branch_vc"
            rules={[
              {
                max: 1,
                message: "Branch VC length must be empty or 1!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Account VC"
            name="account_number_vc"
            rules={[
              {
                max: 1,
                message: "Account VC length must be empty or 1!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Checkbox
            onChange={(e) => setInfo({ ...info, primary: e.target.checked })}
            checked={info.primary}
            className="mb-8"
          >
            primary
          </Checkbox>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
