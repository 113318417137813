import { Button, Form, Select, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { FaList } from "react-icons/fa";
import { MdSave } from "react-icons/md";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { useCTX } from "../../state";
import { setAlert, setProvider } from "../../state/actionCreators";

type TInfo = {
  service_states: string[];
};

export default function StatesServiced() {
  useTitle("States Serviced");

  const [states, setStates] = useState<string[]>([]);
  const [info, setInfo] = useState<TInfo>({ service_states: [] });

  const {
    state: { user, provider },
    dispatch,
  } = useCTX();
  const [updateRequest, updating] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const onSave = () => {
    const _info = new FormData();
    _info.append("email", user!.email);

    const oldStates = [
      ...[...new Set(provider!.service_cities.map((c) => c.state))],
    ];
    const newStates = [...info.service_states];

    const added: any[] = [];

    for (const state of newStates) {
      const osidx = oldStates.findIndex((s) => s === state);

      if (osidx === -1) {
        added.push({ op: "A", entity: "state", instance: state });
      } else {
        oldStates.splice(osidx, 1);
      }
    }

    const geoChanges = [
      ...added,
      ...oldStates.map((s) => ({ op: "R", entity: "state", instance: s })),
    ];
    _info.append("geoChanges", JSON.stringify(geoChanges));

    updateRequest(
      "post",
      "providers/api/providerProfileUpdate",

      (res) => {
        dispatch(
          setProvider({
            ...provider!,
            service_cities: res.data.service_cities,
          })
        );
        dispatch(
          setAlert({
            type: "success",
            message: "STATES SERVICED",
            description: "Service States updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "cities/api/state/list/",
      (res) => {
        setStates(res.data.state_list);
      },
      { email: user?.email }
    );
  }, [user, listRequest]);

  if (loading) {
    <div className="w-[100vw] h-[100vh] flex items-center justify-center">
      <Spin />
    </div>;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center w-full pb-4"
        >
          <FaList className="mr-2" /> States Serviced
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onSave}>
          <Form.Item label="States" name="service_states">
            <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Select states to service..."
              defaultValue={[
                ...new Set(provider?.service_cities.map((c) => c.state)),
              ]}
              onChange={(values) =>
                setInfo({
                  service_states: values,
                })
              }
              options={states.map((s) => ({
                label: s,
                value: s.split(",")[0],
              }))}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
