import { Navigate, Route, Routes } from "react-router-dom";
import PhoneEdit from "./Edit";
import PhoneList from "./List";
import PhoneNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<PhoneList />} />
      <Route path="new" element={<PhoneNew />} />
      <Route path=":id/edit" element={<PhoneEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
