import { Modal, UploadFile } from "antd";
import { useCallback, useState } from "react";

export function useImagePreview() {
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [previewOpen, setPreviewOpen] = useState(false);

  const onPreview = useCallback(async (file: UploadFile) => {
    if (!file.preview) {
      file.preview = await new Promise((resolve, reject) => {
        if (file.thumbUrl?.includes("https://")) {
          resolve(file.thumbUrl);
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj as any);
          reader.onload = () => resolve(reader.result as string);
          reader.onerror = (error) => reject(error);
        }
      });
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  }, []);

  const Preview = () => (
    <Modal
      open={previewOpen}
      title={previewTitle}
      footer={null}
      onCancel={() => setPreviewOpen(false)}
    >
      <img
        alt={previewTitle}
        style={{ width: "100%", height: "400px" }}
        src={previewImage}
      />
    </Modal>
  );

  return { Preview, onPreview };
}
