import { Descriptions, List, Rate, Spin, Tag } from "antd";
import { useEffect, useState } from "react";
import { FaClock, FaMoneyCheck, FaWallet } from "react-icons/fa";
import { IoMdCalendar } from "react-icons/io";
import {
  MdDashboard,
  MdOutlineMiscellaneousServices,
  MdOutlineSyncProblem,
} from "react-icons/md";
import {
  Bar,
  CartesianGrid,
  Cell,
  ComposedChart,
  Legend,
  Line,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import BackButton from "../../components/BackButton";
import { PrivateRoute } from "../../components/Route";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import { formatSeconds } from "../../lib/utils";

const COLORS = ["#8884DB", "#00C49F", "#FFBB28", "#FF8042"];

export default function Dashboard() {
  useTitle("Dashboard");

  const [stats, setStats] = useState<any>();

  const [request, loading] = useRequest();

  useEffect(() => {
    request(
      "post",
      "providers/api/webdashboard/",
      (res) => {
        setStats(res.data.stats);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [request]);

  if (loading) {
    return (
      <div className="h-[100vh] flex items-center justify-center">
        <Spin />
      </div>
    );
  }

  if (!stats) {
    return null;
  }

  const sData = [
    {
      name: "offered",
      value: stats.services.offered,
    },
    {
      name: "rejected",
      value: stats.services.rejected,
    },
    {
      name: "completed",
      value: stats.services.completed,
    },
    {
      name: "cancelled",
      value: stats.services.cancelled.total,
    },
  ];

  const bData = [
    {
      name: "service",
      value: stats.billed.service,
    },
    {
      name: "tips",
      value: stats.billed.tips,
    },
    {
      name: "displacement",
      value: stats.billed.displacement,
    },
  ];

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="flex flex-col items-end text-xs mx-6 mt-6 mb-4">
          <div className="flex items-center w-48">
            <IoMdCalendar className="mr-2" />
            <span className="mr-4">last payment: </span>
            <span>{new Date(stats.last_paym_date).toLocaleDateString()}</span>
          </div>
          <div className="flex items-center w-48">
            <IoMdCalendar className="mr-2" />
            <span className="mr-4">next payment: </span>
            <span>{new Date(stats.next_paym_date).toLocaleDateString()}</span>
          </div>
        </div>
        <div className="flex items-center">
          <MdDashboard className="mr-2" />
          <span className="font-bold">OVERVIEW</span>
        </div>
        <div className="m-6">
          <div className="mb-8 flex">
            <div className="flex justify-between items-center border border-solid border-gray-200 rounded-lg w-[200px] h-[100px] py-4 px-8 mr-4">
              <div className="flex flex-col">
                <span style={{ fontSize: "0.7rem" }}>SERVICES</span>
                <span className="text-xl mt-1">{stats.services.total}</span>
              </div>
              <MdOutlineMiscellaneousServices className="text-4xl opacity-20" />
            </div>
            <div className="flex justify-between items-center border border-solid border-gray-200 rounded-lg w-[200px] h-[100px] py-4 px-8 mr-4">
              <div className="flex flex-col">
                <span style={{ fontSize: "0.7rem" }}>BILLED</span>
                <span className="text-xl mt-1">
                  {stats.billed.total.toLocaleString()}
                </span>
              </div>
              <FaMoneyCheck className="text-4xl opacity-20" />
            </div>
            <div className="flex justify-between items-center border border-solid border-gray-200 rounded-lg w-[200px] h-[100px] py-4 px-8 mr-4">
              <div className="flex flex-col">
                <span style={{ fontSize: "0.7rem" }}>DURATION</span>
                <span className="text-xl mt-1">
                  {formatSeconds(stats.duration)}
                </span>
              </div>
              <FaClock className="text-4xl opacity-20" />
            </div>
          </div>
          <div className="my-4 flex justify-around">
            <div className="flex flex-col gap-10">
              <ComposedChart width={400} height={200} data={sData}>
                <Bar dataKey="value" fill="#8884d8" />
                <Line type="monotone" dataKey="value" stroke="#ff7300" />
                <XAxis dataKey="name" fontSize={10} />
                <YAxis allowDecimals={false} fontSize={10} />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  content={({ active, label, payload }) =>
                    active &&
                    payload && (
                      <div className="flex flex-col items-center bg-white p-4 rounded-lg">
                        <span style={{ fontSize: "0.7rem" }}>{label}</span>
                        <span className="font-bold mt-1">
                          {payload[0]?.value}
                        </span>
                      </div>
                    )
                  }
                />
              </ComposedChart>
              <PieChart width={400} height={200}>
                <Pie
                  data={bData}
                  cx={"50%"}
                  cy={"50%"}
                  innerRadius={60}
                  outerRadius={80}
                  fill="#8884d8"
                  paddingAngle={5}
                  dataKey="value"
                  nameKey="name"
                  label
                  fontSize={10}
                  fontWeight={"bold"}
                >
                  {bData.map((_, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Legend
                  style={{ fontSize: "0.7rem" }}
                  content={({ payload }) => (
                    <div className="flex items-center justify-end mt-4">
                      {payload?.map((entry, index) => (
                        <div
                          key={`item-${index}`}
                          className="flex items-center mr-4"
                          style={{ fontSize: "0.7rem" }}
                        >
                          <div
                            className="w-2 h-2 mr-2"
                            style={{
                              backgroundColor: entry.color,
                            }}
                          ></div>
                          <span style={{ color: entry.color }}>
                            {entry.value}
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </PieChart>
            </div>
            <div className="flex flex-col items-center justify-between">
              <div className="flex flex-col items-center justify-center border border-solid border-white border-t-0 border-b-0 rounded-full w-40 h-40">
                <span style={{ fontSize: "0.7rem" }}>{stats.rating}</span>
                <Rate allowHalf defaultValue={stats.rating} />
                <span style={{ fontSize: "0.7rem" }} className="mt-1">
                  ({Object.keys(stats.orders).length})
                </span>
              </div>
              <div className="flex flex-col items-center justify-center border border-solid border-gray-200 border-t-0 border-b-0 rounded-full w-32 h-32">
                <span style={{ fontSize: "0.7rem" }}>POPULARITY</span>
                <span className="text-xl">{stats.popularity}</span>
              </div>
              <div className="flex flex-col items-center justify-center border border-solid border-gray-200 border-t-0 border-b-0 rounded-full w-32 h-32">
                <span style={{ fontSize: "0.7rem" }}>EFFECTIVENESS</span>
                <span className="text-xl">{stats.effectiveness}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 mb-16">
          <div className="flex items-center">
            <FaWallet className="mr-2" />
            <span className="font-bold">WALLET</span>
          </div>
          <div className="flex m-6">
            <div className="flex justify-between items-center border border-solid border-gray-200 rounded-lg w-[200px] h-[100px] py-4 px-8 mr-4">
              <div className="flex flex-col">
                <span style={{ fontSize: "0.7rem" }}>BALANCE</span>
                <span className="text-xl mt-1">
                  {stats.wallet.toLocaleString()}
                </span>
              </div>
              <FaWallet className="text-4xl opacity-20" />
            </div>
            <div className="flex justify-between items-center border border-solid border-gray-200 rounded-lg w-[200px] h-[100px] py-4 px-8 mr-4">
              <div className="flex flex-col">
                <span style={{ fontSize: "0.7rem" }}>NEXT PAYMENT</span>
                <span className="text-xl mt-1">
                  {new Date(stats.next_paym_date).toLocaleDateString()}
                </span>
              </div>
              <IoMdCalendar className="text-4xl opacity-20" />
            </div>
          </div>
        </div>
        {Object.keys(stats.orders).length > 0 && (
          <div className="my-8">
            <div className="flex items-center">
              <MdOutlineMiscellaneousServices className="mr-2" />
              <span className="font-bold">ORDERS</span>
            </div>
            <div className="m-6">
              {Object.entries(stats.orders).map(([oid, o]: any) => (
                <div key={oid} className="flex flex-col mb-8">
                  <span className="text-xs">{oid}</span>
                  <div className="mt-2 mb-6 flex items-center">
                    <Rate
                      allowHalf
                      defaultValue={o.rating}
                      className="text-xs"
                    />
                    <span className="ml-2 text-xs">({o.rating})</span>
                  </div>
                  <div>
                    {o.services.map((s: any) => (
                      <div
                        key={s.id}
                        className="border border-solid border-gray-200 rounded-lg p-4 mb-4"
                      >
                        <Descriptions>
                          <Descriptions.Item label="Service">
                            {s.service_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Customer">
                            {s.customer_name}
                          </Descriptions.Item>
                          <Descriptions.Item label="Price">
                            {s.price.toLocaleString()}
                          </Descriptions.Item>
                          <Descriptions.Item label="Duration">
                            {formatSeconds(s.duration)}
                          </Descriptions.Item>
                          <Descriptions.Item label="Displacement">
                            {s.displacement.toLocaleString()}
                          </Descriptions.Item>
                          <Descriptions.Item label="Tips">
                            {s.tips.toLocaleString()}
                          </Descriptions.Item>
                          <Descriptions.Item label="Start Date">
                            {new Date(s.start_date).toLocaleDateString()}
                          </Descriptions.Item>
                          <Descriptions.Item label="End Date">
                            {new Date(s.end_date).toLocaleDateString()}
                          </Descriptions.Item>
                        </Descriptions>
                        {s.disputes.length > 0 && (
                          <div className="mt-4">
                            <div className="flex items-center mb-2">
                              <MdOutlineSyncProblem className="mr-2" />
                              <span style={{ fontSize: "0.7rem" }}>
                                DISPUTES
                              </span>
                            </div>
                            <List
                              bordered
                              itemLayout="vertical"
                              dataSource={s.disputes}
                              renderItem={(item: any) => (
                                <List.Item
                                  key={item.id}
                                  extra={
                                    <img
                                      alt="Dispute"
                                      className="w-32 h-32"
                                      src={
                                        item.photo || "/images/covers/empty.png"
                                      }
                                    />
                                  }
                                >
                                  <List.Item.Meta
                                    title={item.problem}
                                    description={new Date(
                                      item.date
                                    ).toLocaleDateString()}
                                  />
                                  <div className="flex flex-col">
                                    <span>{item.description}</span>
                                    <Tag
                                      color={
                                        item.status === "P"
                                          ? "warning"
                                          : "success"
                                      }
                                      className="max-w-max mt-2"
                                    >
                                      {item.status === "P"
                                        ? "pending"
                                        : "active"}
                                    </Tag>
                                  </div>
                                </List.Item>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </PrivateRoute>
  );
}
