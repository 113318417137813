import { Button, Modal, Spin, Table, TableProps, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdDeleteOutline, MdEdit } from "react-icons/md";
import { TbCertificate } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { ICertificate, MDegreeLevel } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function CertificateList() {
  useTitle("Certificate List");

  const [certificates, setCertificates] = useState<ICertificate[]>([]);

  const [deleteCertificate, setDeleteCertificate] =
    useState<ICertificate | null>(null);

  const navigate = useNavigate();
  const { dispatch } = useCTX();

  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<ICertificate>["columns"] = useMemo(
    () => [
      {
        key: "title",
        title: "Title",
        dataIndex: "title",
      },
      {
        key: "date_issued",
        title: "Date Issued",
        dataIndex: "date_issued",
      },
      {
        key: "degree_level",
        title: "Level",
        dataIndex: "degree_level",
        render: (_, cert: any) => (
          <span>{MDegreeLevel.getKey(cert.degree_level)}</span>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, cert: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeleteCertificate(cert)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/provider/certificates/${cert.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "certificates/api/webdelete/",
      () => {
        setCertificates((old) =>
          old.filter((p) => p.id !== deleteCertificate?.id)
        );
        setDeleteCertificate(null);
        dispatch(
          setAlert({
            type: "success",
            message: "CERTIFICATE",
            description: "Certificate deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        cert_id: deleteCertificate?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "certificates/api/weblist/",
      (res) => {
        setCertificates(res.data.certificates);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteCertificate}
        title="Delete Certificate"
        onCancel={() => setDeleteCertificate(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete certificate '
          {deleteCertificate?.title}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <TbCertificate className="mr-2" /> Certificates
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/provider/certificates/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={certificates} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
