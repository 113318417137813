import { Navigate, Route, Routes } from "react-router-dom";
import FactEdit from "./Edit";
import FactList from "./List";
import FactNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<FactList />} />
      <Route path="new" element={<FactNew />} />
      <Route path=":id/edit" element={<FactEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
