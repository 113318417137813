import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./Login";
import Register from "./Register";
import VerifyAccount from "./VerifyAccount";

export default function Layout() {
  return (
    <Routes>
      <Route path="register" element={<Register />} />
      <Route path="login" element={<Login />} />
      <Route path="verify/:vid" element={<VerifyAccount />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
