import { RcFile } from "antd/es/upload";

export const getBase64 = (file: RcFile | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export class Mapper {
  public keys: string[];
  public values: string[];

  constructor(keys: string[], values: string[]) {
    this.keys = keys;
    this.values = values;
  }

  getKey(value: string) {
    const vidx = this.values.findIndex((v) => v === value);
    return this.keys[vidx];
  }

  getValue(key: string) {
    const kidx = this.keys.findIndex((k) => k === key);
    return this.values[kidx];
  }
}

export function formatSeconds(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(secs).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export function getDomain() {
  return window.location.hostname === "localhost"
    ? "localhost"
    : `server.${window.location.hostname}`;
}

export function getServerDomain() {
  return window.location.hostname === "localhost"
    ? "http://localhost:8000/"
    : `https://server.${window.location.hostname}/`;
}

export function getWorkflowTitle(type: string) {
  if (type === "PS") return "Provider subscription";
  else if (type === "ES") return "Entity subscription";
  else if (type === "OS") return "Offering subscription";
  else if (type === "PB") return "Provider billing";
  else if (type === "ELS") return "Entity link subscription";
}

export function formatDuration(days: number, hours: number, minutes: number) {
  return [
    days ? `${days} day${days > 1 ? "s" : ""}` : "",
    hours ? `${hours} hour${hours > 1 ? "s" : ""}` : "",
    minutes ? `${minutes} minute${minutes > 1 ? "s" : ""}` : "",
  ]
    .filter(Boolean)
    .join(", ")
    .replace(/,([^,]*)$/, " and$1");
}
