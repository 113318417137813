import { Button, Form, Input, Typography } from "antd";
import { useCallback } from "react";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  email: string;
};

export default function EmailNew() {
  useTitle("New Email");

  const navigate = useNavigate();
  const { dispatch } = useCTX();
  const [request, loading] = useRequest();

  const onCreate = useCallback(
    (values: TInfo) => {
      request(
        "post",
        "users/api/webmailcreate/",
        () => {
          navigate(-1);
          dispatch(
            setAlert({
              type: "success",
              message: "EMAIL",
              description:
                "Email created successfully, verification email has been sent!",
            })
          );
        },
        {
          token: localStorage.getItem("token"),
          email: values.email,
        }
      );
    },
    [dispatch, navigate, request]
  );

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <IoAdd className="mr-2" /> Email
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onCreate}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input the email!" },
              { type: "email", message: "Please input a valid email!" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
