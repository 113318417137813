import { Navigate, Route, Routes } from "react-router-dom";
import AccountEdit from "./Edit";
import AccountList from "./List";
import AccountNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<AccountList />} />
      <Route path="new" element={<AccountNew />} />
      <Route path=":id/edit" element={<AccountEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
