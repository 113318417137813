import { Button, Modal, Spin, Table, TableProps, Tag, Typography } from "antd";
import { useEffect, useMemo, useState } from "react";
import { MdAdd, MdDeleteOutline, MdEdit, MdLocalOffer } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IOffering } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function OfferingList() {
  useTitle("Offering List");

  const [offerings, setOfferings] = useState<IOffering[]>([]);

  const [deleteOffering, setDeleteOffering] = useState<IOffering | null>(null);

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();

  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IOffering>["columns"] = useMemo(
    () => [
      {
        key: "offer_name",
        title: "Name",
        dataIndex: "offer_name",
      },
      {
        key: "offer_start_date",
        title: "Start Date",
        dataIndex: "offer_start_date",
      },
      {
        key: "price",
        title: "Price",
        dataIndex: "price",
      },
      {
        key: "status",
        title: "Status",
        dataIndex: "status",
        render: (s) => (
          <Tag color={s === "A" ? "green" : "yellow"}>
            {s === "A" ? "Active" : "Review"}
          </Tag>
        ),
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, offering: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              disabled={offering.status !== "A"}
              onClick={() => setDeleteOffering(offering)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              disabled={offering.status !== "A"}
              onClick={() =>
                navigate(`/provider/offerings/${offering.id}/edit`)
              }
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "providers/api/providerOfferingUpdate",
      () => {
        setOfferings((old) => old.filter((o) => o.id !== deleteOffering?.id));
        setDeleteOffering(null);
        dispatch(
          setAlert({
            type: "success",
            message: "OFFERING",
            description: "Offering deleted successfully!",
          })
        );
      },
      {
        email: user?.email,
        operation: "R",
        offeringId: deleteOffering?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "providers/api/offering/weblist/",
      (res) => {
        setOfferings(res.data.offerings);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deleteOffering}
        title="Delete Offering"
        onCancel={() => setDeleteOffering(null)}
        okText="Delete"
        onOk={onDelete}
        confirmLoading={deleting}
      >
        <span className="text-xs">
          Are you sure that you want to delete offering '
          {deleteOffering?.offer_name}
          '?
        </span>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <MdLocalOffer className="mr-2" /> Offerings
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/provider/offerings/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={offerings} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
