import { Tabs, TabsProps, Typography } from "antd";
import { useMemo, useState } from "react";
import { CiLock } from "react-icons/ci";
import { PrivateRoute } from "../../components/Route";
import Event from "./Event";
import Workflow from "./Workflow";

export default function Authorization() {
  const [activeTab, setActiveTab] = useState("events");

  const tabs: TabsProps["items"] = useMemo(() => {
    const _ = [
      { key: "events", label: "Events" },
      { key: "workflows", label: "Workflows" },
    ];
    return _;
  }, []);

  return (
    <PrivateRoute>
      <div className="w-full">
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <CiLock className="mr-2" /> Authorizations
        </Typography.Title>
        <Tabs
          items={tabs}
          activeKey={activeTab}
          onTabClick={(k) => setActiveTab(k)}
          className="w-40 my-4 mx-8"
        />
        {activeTab === "events" ? <Event /> : <Workflow />}
      </div>
    </PrivateRoute>
  );
}
