import { Navigate, Route, Routes } from "react-router-dom";
import CertificateEdit from "./Edit";
import CertificateList from "./List";
import CertificateNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<CertificateList />} />
      <Route path="new" element={<CertificateNew />} />
      <Route path=":id/edit" element={<CertificateEdit />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
