import {
  Button,
  DatePicker,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { TbCertificate } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { MDegreeLevel, MDurationUnit } from "../../../lib/model";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  degree_level: string;
  duration_unit: string;
  photo: UploadFile[];
};

export default function CertificateNew() {
  useTitle("New Certificate");

  const [info, setInfo] = useState<TInfo>({
    degree_level: "1",
    duration_unit: "1",
    photo: [],
  });

  const navigate = useNavigate();
  const { dispatch } = useCTX();
  const { Preview, onPreview } = useImagePreview();
  const [request, loading] = useRequest();

  const onAdd = (values: any) => {
    const _info = new FormData();
    _info.append("token", localStorage.getItem("token")!);
    _info.append("operation", "A");
    _info.append("title", values.title);
    _info.append("institution", values.institution);
    _info.append("degree_level", info.degree_level);
    _info.append("duration_unit", info.duration_unit);
    _info.append("duration_number", values.duration_number);
    _info.append("date_issued", values.date_issued.format("YYYY-MM-DD"));
    _info.append("descr", values.descr);
    _info.append("photo", info.photo[0].originFileObj!);

    request(
      "post",
      "certificates/api/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "CERTIFICATE",
            description: "Certificate added successfully!",
          })
        );
      },
      _info
    );
  };

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <TbCertificate className="mr-2" /> Certificate
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onAdd}>
          <Form.Item
            label="Photo"
            name="photo"
            rules={[
              {
                required: true,
                validator: () => {
                  if (info.photo.length === 0) {
                    return Promise.reject("Please add a photo!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Upload
              listType="picture-card"
              fileList={info.photo}
              onPreview={onPreview}
              beforeUpload={() => false}
              previewFile={getBase64 as any}
              onChange={({ fileList }) => setInfo({ ...info, photo: fileList })}
            >
              {info.photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Please input certificate title!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Institution"
            name="institution"
            rules={[
              { required: true, message: "Please input the institution!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Level" name="degree_level" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MDegreeLevel.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      degree_level: MDegreeLevel.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.degree_level
                  ? MDegreeLevel.getKey(info.degree_level)
                  : "Select the level"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item label="Duration Unit" name="duration_unit" required>
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MDurationUnit.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      duration_unit: MDurationUnit.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.duration_unit
                  ? MDurationUnit.getKey(info.duration_unit)
                  : "Select the duration unit"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Duration"
            name="duration_number"
            initialValue={0}
            required
          >
            <InputNumber />
          </Form.Item>
          <Form.Item label="Issue Date" name="date_issued" required>
            <DatePicker disabledDate={(d) => d.isAfter(dayjs())} />
          </Form.Item>
          <Form.Item label="Description" name="descr" required>
            <TextArea rows={8} />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
