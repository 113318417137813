import {
  Button,
  DatePicker,
  Form,
  Input,
  Typography,
  Upload,
  UploadFile,
} from "antd";
import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useImagePreview } from "../../../hooks/useImagePreview";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { getBase64 } from "../../../lib/utils";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  entity_photo: UploadFile[];
};

export default function EntityNew() {
  useTitle("New Entity");

  const [info, setInfo] = useState<TInfo>({ entity_photo: [] });

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const { Preview, onPreview } = useImagePreview();
  const [request, loading] = useRequest();

  const onCreate = (values: any) => {
    const _info = new FormData();
    _info.append("email", user!.email);
    _info.append("entity_tax_id", values.entity_tax_id);
    _info.append("entity_name", values.entity_name);
    _info.append("doo", values.doo.format("YYYY-MM-DD"));
    _info.append("coo", values.coo);
    _info.append("entity_fantasy_name", values.entity_fantasy_name);
    _info.append("entity_size", values.entity_size);
    _info.append("mainEcoAct", values.mainEcoAct);
    _info.append("secEcoAct", values.secEcoAct);
    _info.append("legalNature", values.legalNature);

    if (info.entity_photo.length > 0)
      _info.append("entity_photo", info.entity_photo[0].originFileObj!);

    request(
      "post",
      "entities/api/add/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "ENTITY",
            description:
              "Entity has been created successfully! It will be reviewed shortly by admin.",
          })
        );
      },
      _info
    );
  };

  return (
    <PrivateRoute>
      <Preview />
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <IoAdd className="mr-2" /> Entity
        </Typography.Title>
        <Form labelCol={{ span: 8 }} labelAlign="left" onFinish={onCreate}>
          <Form.Item
            label="Tax ID"
            name="entity_tax_id"
            rules={[
              { required: true, message: "Please input the entity tax id!" },
            ]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Name"
            name="entity_name"
            rules={[
              { required: true, message: "Please input the entity name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Opening Date"
            name="doo"
            required
            rules={[
              () => ({
                validator: (_, value) => {
                  if (!value)
                    return Promise.reject(
                      new Error("Please input the opening date!")
                    );
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            label="Opening Country (ISO)"
            name="coo"
            rules={[
              { required: true, message: "Please input the opening country!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Photo" name="entity_photo">
            <Upload
              listType="picture-card"
              fileList={info.entity_photo}
              onPreview={onPreview}
              previewFile={getBase64 as any}
              onChange={({ fileList }) =>
                setInfo({ ...info, entity_photo: fileList })
              }
              className="mb-8"
            >
              {info.entity_photo.length > 0 ? null : (
                <div className="flex flex-col items-center">
                  <AiOutlinePlus className="mb-2" />
                  <Typography.Text className="text-xs">Upload</Typography.Text>
                </div>
              )}
            </Upload>
          </Form.Item>
          <Form.Item label="Fantasy Name" name="entity_fantasy_name">
            <Input />
          </Form.Item>
          <Form.Item label="Establishment Size" name="entity_size">
            <Input />
          </Form.Item>
          <Form.Item label="Main Activity Code/Description" name="mainEcoAct">
            <Input />
          </Form.Item>
          <Form.Item
            label="Secondary Activity Code/Description"
            name="secEcoAct"
          >
            <Input />
          </Form.Item>
          <Form.Item label="Legal Status Code/Description" name="legalNature">
            <Input />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
