import { Button, Descriptions, Image, Spin, Typography } from "antd";
import { Fragment, useEffect, useState } from "react";
import { MdLocalOffer, MdMiscellaneousServices } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../components/BackButton";
import { useRequest } from "../../hooks/useRequest";
import { useTitle } from "../../hooks/useTitle";
import {
  MPriceType,
  MVariableDistanceUnit,
  MVariableTimeUnit,
  MVariableUnit,
} from "../../lib/model";
import { formatDuration } from "../../lib/utils";
import { useCTX } from "../../state";

export default function ServiceDetail() {
  useTitle("Service Detail");

  const [service, setService] = useState<any>(null);

  const {
    state: { user, settings, isocode },
  } = useCTX();
  const navigate = useNavigate();
  const params = useParams();

  const [request, loading] = useRequest(true);

  useEffect(() => {
    request(
      "post",
      "services/api/serviceDetail/",
      (res) => {
        const s = res.data.service;
        setService({
          ...s,
          service_long_descr: s.long_descr,
          service_price: s.price,
        });
      },
      {
        email: user?.email,
        serviceId: params.id,
      }
    );
  }, [params.id, request, user?.email]);

  useEffect(() => {
    if (!loading && !service) {
      navigate(-1);
    }
  }, [loading, service, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!service) {
    return null;
  }

  return (
    <div className="w-full font-poppins">
      <BackButton />
      <div className="flex flex-col lg:flex-row items-center">
        <div className="flex flex-col w-full lg:w-1/4">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center pb-2"
          >
            <Image
              src={service.service_image}
              alt={service.service_image}
              preview={false}
              height={64}
              width={64}
              className="pr-4 object-contain"
            />
            {service.service_name}
          </Typography.Title>
          {service.service_image ? (
            <Image
              src={service.service_image_web}
              className="rounded-md object-cover"
              width={300}
              height={200}
              preview={false}
            />
          ) : (
            <MdMiscellaneousServices className="text-9xl text-gray-300" />
          )}
        </div>
        <div className="w-full lg:w-3/4 ml-0 lg:ml-32 mt-8 lg:mt-20">
          <Descriptions layout="vertical">
            <Descriptions.Item label="Price">
              {isocode?.currency_symbol}. {service.service_price}
            </Descriptions.Item>
            <Descriptions.Item label="Duration">
              {formatDuration(
                service.exec_time_days,
                service.exec_time_hours,
                service.exec_time_mins
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Price Type">
              {MPriceType.getKey(service.price_type)}
            </Descriptions.Item>
            {service.price_type === "V" && (
              <Fragment>
                <Descriptions.Item label="Variable Unit">
                  {MVariableUnit.getKey(service.variable_unit)}
                </Descriptions.Item>
                {service.variable_unit === "T" ? (
                  <Fragment>
                    <Descriptions.Item label="Variable Time Unit">
                      {MVariableTimeUnit.getKey(service.var_time_unit)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Variable Time Price Unit">
                      {isocode?.currency_symbol}. {service.var_time_price_unit}
                    </Descriptions.Item>
                    <Descriptions.Item label="Variable Time Minimum Unit">
                      {service.var_time_min_unit}
                    </Descriptions.Item>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Descriptions.Item label="Variable Distance Unit">
                      {MVariableDistanceUnit.getKey(service.var_distance_unit)}
                    </Descriptions.Item>
                    <Descriptions.Item label="Variable Distance Price Unit">
                      {isocode?.currency_symbol}.{" "}
                      {service.var_distance_price_unit}
                    </Descriptions.Item>
                    <Descriptions.Item label="Variable Distance Minimum Unit">
                      {service.var_distance_min_unit}
                    </Descriptions.Item>
                  </Fragment>
                )}
              </Fragment>
            )}
            <Descriptions.Item label="Category">
              {service.category_name}
            </Descriptions.Item>
            <Descriptions.Item label="Subcategory">
              {service.subcategory_name}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row justify-between my-8">
        <div className="flex flex-col">
          <div className="flex flex-col">
            <span className="text-gray-400 text-sm">Description</span>
            <span className="text-xs mt-2 whitespace-pre-wrap">
              {service.service_long_descr}
            </span>
          </div>
          <div className="flex flex-col mt-8">
            <span className="text-gray-400 text-sm">Terms & Conditions</span>
            <span className="text-xs mt-2 whitespace-pre-wrap">
              {service.tcs}
            </span>
          </div>
        </div>
        {settings.type === "provider" && (
          <Button
            type="primary"
            className="text-xs mt-8 lg:mt-0 ml-0 lg:ml-8"
            icon={<MdLocalOffer size={10} />}
            onClick={() =>
              navigate("/provider/offerings/new", {
                state: {
                  service_id: service.service_id,
                },
              })
            }
          >
            Offer
          </Button>
        )}
      </div>
    </div>
  );
}
