import { Button, Dropdown, Form, Input, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { MdEdit, MdSave } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IAddress, MAddressType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

export default function AddressEdit() {
  useTitle("Edit Address");

  const [address, setAddress] = useState<IAddress>();
  const [addressType, setAddressType] = useState("");

  const {
    state: { user },
    dispatch,
  } = useCTX();
  const params = useParams();
  const navigate = useNavigate();
  const [updateRequest, updating] = useRequest();
  const [detailRequest, loading] = useRequest(true);

  const onSave = (values: any) => {
    const _info = {
      ...address,
      ...values,
      email: user?.email,
      addressId: address?.addrId,
    };

    updateRequest(
      "post",
      "addresses/api/addUpdateAddress/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "ADDRESS",
            description: "Address updated successfully!",
          })
        );
      },
      _info
    );
  };

  useEffect(() => {
    detailRequest(
      "post",
      "addresses/api/addressDetail/",
      (res) => {
        setAddress(res.data.address);
        setAddressType(res.data.address.address_type);
      },
      {
        email: user?.email,
        addressId: params.id,
      }
    );
  }, [user, params, dispatch, detailRequest]);

  useEffect(() => {
    if (!loading && !address) {
      navigate(-1);
    }
  }, [loading, address, navigate]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (!address) {
    return null;
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <MdEdit className="mr-2" /> Address
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{
            name: address.name,
            address1: address.address1,
            address2: address.address2,
            zipcode: address.zipcode,
            complement: address.complement,
            number: address.number,
            neighborhood: address.neighborhood,
            city: address.city,
            state: address.state,
            country: address.country,
            reference: address.reference,
            pobox: address.pobox,
            address_type: address.address_type,
          }}
          onFinish={onSave}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              { required: true, message: "Please input the address name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Type"
            name="address_type"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!addressType) {
                    return Promise.reject("Please input address type!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MAddressType.keys.map((k) => ({
                  key: k,
                  label: k,
                  onClick: () => setAddressType(MAddressType.getValue(k)),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {addressType ? MAddressType.getKey(addressType) : "Select type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Address 1"
            name="address1"
            rules={[{ required: true, message: "Please input the address 1!" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please input the city!" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please input the state!" }]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Country (ISO)"
            name="country"
            rules={[
              { required: true, message: "Please input the country iso code!" },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item label="Address 2" name="address2">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Neighborhood" name="neighborhood">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Zipcode" name="zipcode">
            <Input disabled />
          </Form.Item>
          <Form.Item label="Street Number" name="number">
            <Input />
          </Form.Item>
          <Form.Item label="P.O Box" name="pobox">
            <Input />
          </Form.Item>
          <Form.Item label="Reference" name="reference">
            <Input />
          </Form.Item>
          <Form.Item label="Complement" name="complement">
            <Input />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<MdSave size={10} />}
              loading={updating}
              htmlType="submit"
            >
              Save
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
