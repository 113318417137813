import { AxiosResponse } from "axios";
import { useCallback, useState } from "react";
import { caxios } from "../lib/constants";
import { useCTX } from "../state";
import { setAlert } from "../state/actionCreators";

export function useRequest(defaultLoading = false) {
  const [loading, setLoading] = useState(defaultLoading);

  const { dispatch } = useCTX();

  const request = useCallback(
    (
      method: "get" | "post",
      url: string,
      onFinish: (res: any) => any,
      data?: any
    ): Promise<AxiosResponse> => {
      setLoading(true);

      const token = localStorage.getItem("token");
      return caxios[method](url, data, {
        ...(token ? { headers: { Authorization: `Token ${token}` } } : {}),
      })
        .then(onFinish)
        .catch((e) => {
          console.error(e);
          if (e.response.status === 401) {
            localStorage.removeItem("token");
          } else {
            try {
              dispatch(
                setAlert({
                  type: "error",
                  message: "ERROR",
                  description:
                    e.response.data.errors[
                      Object.keys(e.response.data.errors)[0]
                    ][0],
                })
              );
            } catch {
              dispatch(
                setAlert({
                  type: "error",
                  message: "ERROR",
                  description: e.request.responseText,
                })
              );
            }
          }
        })
        .finally(() => setLoading(false));
    },
    [dispatch]
  );

  return [request, loading] as const;
}
