import { IoMdArrowBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <IoMdArrowBack
      className="my-8 text-gray-500 cursor-pointer"
      onClick={() => navigate(-1)}
    />
  );
}
