import { Navigate, Route, Routes } from "react-router-dom";
import EntityList from "./List";
import EntityNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<EntityList />} />
      <Route path="new" element={<EntityNew />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
