import { Spin, Switch } from "antd";
import { useEffect } from "react";
import { RiUser2Fill, RiUser3Fill } from "react-icons/ri";
import { useRequest } from "../hooks/useRequest";
import { useCTX } from "../state";
import { setProvider, setSettings } from "../state/actionCreators";

export default function SwitchButton() {
  const {
    state: { settings, provider },
    dispatch,
  } = useCTX();
  const [request, loading] = useRequest();

  useEffect(() => {
    if (settings.type === "provider" && !provider) {
      request(
        "post",
        "providers/api/providerDetail/",
        (res) => {
          dispatch(setProvider(res.data.provider));
          localStorage.setItem("userType", "provider");
        },
        {
          token: localStorage.getItem("token"),
        }
      );
    } else if (settings.type === "customer" && provider) {
      localStorage.setItem("userType", "customer");
      dispatch(setProvider(null));
    }
  }, [settings, provider, dispatch, request]);

  if (loading) {
    return <Spin size="small" />;
  }

  return (
    <Switch
      className="ml-2 hidden sm:block"
      style={{
        backgroundColor: settings.color,
      }}
      checkedChildren={<RiUser2Fill />}
      unCheckedChildren={<RiUser3Fill />}
      checked={settings.type === "provider"}
      onChange={(checked) =>
        dispatch(
          setSettings({
            type: checked ? "provider" : "customer",
            color: checked ? "#4404e4" : "#1cd494",
          })
        )
      }
    />
  );
}
