import { Mapper } from "./utils";

export const MAddressType = new Mapper(
  ["Home", "Other", "Work"],
  ["H", "O", "W"]
);

export const MLink = new Mapper(
  [
    "ADMINISTRATOR",
    "EMPLOYEE",
    "PARTNER",
    "POWER OF ATTORNEY",
    "REPRESENTATIVE",
  ],
  ["A", "E", "P", "L", "R"]
);

export const MDocumentType = new Mapper(
  ["CNH Brazil", "RG Brazil", "RNM Brazil", "Aadhar Card", "Passport"],
  ["CNH", "RG", "RNM", "UIDAI", "Passaporte"]
);

export const MCalendarMode = new Mapper(
  ["Automatic", "Semi-Automatic"],
  ["A", "S"]
);

export const MGender = new Mapper(["Female", "Male"], ["F", "M"]);

export const MPhoneType = new Mapper(
  ["MOBILE", "HOME", "WORK", "OTHER"],
  ["1", "2", "3", "4"]
);

export const MDegreeLevel = new Mapper(
  [
    "University",
    "Master",
    "PHD",
    "Certificate",
    "Seminar",
    "Technical",
    "Other",
  ],
  ["1", "2", "3", "4", "5", "6", "9"]
);

export const MAccountType = new Mapper(
  ["Current Account", "Savings Account"],
  ["1", "2"]
);

export const MDurationUnit = new Mapper(
  ["Hours", "Days", "Weeks", "Months", "Years"],
  ["1", "2", "3", "4", "5"]
);

export const MPhoneCountry = new Mapper(
  ["Brazil", "Dominican Republic", "India", "Uruguay", "USA"],
  ["55", "1", "91", "598", "11"]
);

export const MPriceType = new Mapper(
  ["Fix", "Variable", "Mix"],
  ["F", "V", "M"]
);

export const MVariableUnit = new Mapper(
  ["Distance", "Time", "Custom"],
  ["D", "T", "C"]
);

export const MVariableTimeUnit = new Mapper(
  [
    "Minutes",
    "Hours",
    "Days",
    "Weeks",
    "Months",
    "Quarters",
    "Semesters",
    "Years",
  ],
  ["1", "2", "3", "4", "5", "6", "7", "8"]
);

export const MVariableDistanceUnit = new Mapper(
  ["Foot", "Meter", "Mile", "Kilometer"],
  ["1", "2", "3", "4"]
);

export interface ICategory {
  categ_id: number;
  categ_descr: string;
  categ_icon: string;
  categ_name: string;
}

export interface IEntity {
  id: number;
  name: string;
  fantasy_name: string;
  photo: string;
  isAdmin: boolean;
}

export interface ILink {
  id: number;
  status: string;
  entity_link_id: number;
  entity_link_status: string;
  entity_id: number;
  entity_nick: string;
  entity_link: string;
  entity_photo: string;
  entity_doc: string;
  link_started: string;
  link_comments: string;
  provider: string;
}

export interface ICity {
  id: number;
  name: string;
  state: string;
  country: string;
}

export interface IService {
  category_name: string;
  subcategory_name: string;
  service_id: number;
  service_name: string;
  service_long_descr: string;
  service_image: string;
  service_image_web: string;
  service_price_type: string;
  service_price: number;
  service_variable_unit: string;
  service_var_time_unit: string;
  service_var_time_price_unit: number;
  service_var_time_min_unit: number;
  service_var_distance_unit: string;
  service_var_distance_price_unit: number;
  service_var_distance_min_unit: number;
  exec_time: string;
  service_exec_time_days: number;
  service_exec_time_hours: number;
  service_exec_time_mins: number;
  service_tcs: string;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  display_name: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  last_name_mother: string;
  last_name_father: string;
  avatar: string;
  cob: string;
  dtob: string;
  gender: string;
  doc_type: string;
  doc_issuer: string;
  doc_photo: string;
  doc_id: string;
  tax_id: string;
  rows_per_page: number;
  prov_status: "A" | "R" | "N";
  agent_status: "A" | "N";
  agent_is_manager: boolean;
  support_status: "A" | "R" | "W" | "N";
}

export interface IIsocode {
  country_name: string;
  country_continent: string;
  country_currency: string;
  currency_symbol: string;
  distance_unit: string;
  calling_code: number;
}

export interface IProvider {
  status: string;
  photo: string;
  profession: string;
  experience: string;
  calendar: string;
  company: string;
  city: ICity;
  service_cities: ICity[];
}

export interface IPhone {
  id: number;
  phone_name: string;
  phone_number: string;
  phone_type: string;
  country: string;
  primary: boolean;
}

export interface IEmail {
  id: number;
  email: string;
  verified: boolean;
  primary: boolean;
}

export interface IAddress {
  id: number;
  addrId: number;
  name: string;
  address1: string;
  address2: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  reference: string;
  pobox: string;
  address_type: string;
  lat: number;
  lng: number;
}

export interface IEntity {
  id: number;
  name: string;
  fantasy_name: string;
  photo: string;
}

export interface ICertificate {
  id: number;
  title: string;
  institution: string;
  degree_level: string;
  duration_unit: string;
  duration_number: number;
  date_issued: string;
  photo: string;
  descr: string;
}

export interface IOffering {
  id: number;
  status: string;
  offer_name: string;
  offer_descr: string;
  offer_photo: string;
  offer_start_date: string;
  price: number;
  price_type: string;
  variable_unit: string;
  var_time_unit: string;
  var_distance_unit: string;
  var_time_price_unit: number;
  var_distance_price_unit: number;
  var_time_min_unit: number;
  var_distance_min_unit: number;
  exec_time: string;
  exec_time_days: number;
  exec_time_hours: number;
  exec_time_mins: number;
  tcs: string;
}

export interface IPhoto {
  id: number;
  title: string;
  descr: string;
  photo: string;
  service: string;
  customer_email: string;
}

export interface IBank {
  id: number;
  bank_name: string;
  bank_logo: string;
  country: string;
}

export interface IAccount {
  id: number;
  account_name: string;
  account_type: string;
  account_owner: string;
  account_owner_ID: string;
  account_branch: string;
  account_branch_vc: string;
  account_number: string;
  account_number_vc: string;
  IFSC_Code: string;
  GST_number: string;
  PAN_number: string;
  primary: boolean;
  bank: IBank;
}

export interface IDispute {
  disputeId: number;
  title: string;
  photo: string;
  description: string;
  status: string;
  "offer name": string;
  "customer name": string;
  service_date: string;
  creation_date: string;
  Facts: IFact[];
}

export interface IFact {
  dispute: IDispute;
  factId: number;
  title: string;
  photo: string;
  document: string;
  description: string;
  creation_date: string;
}

export interface IAppointment {
  id: number;
  date: string;
  service_name: string;
  status: string;
  group: number;
  from_time: string;
  to_time: string;
  customer: { id: number; name: string };
  services: string[];
  order_number: string;
  short_descr: string;
  notes: string;
  address: IAddress;
}

export interface IMessage {
  id: string;
  senderMail: string;
  receiverMail: string;
  agentMail: string;
  messageType: string;
  body: string;
  imageURL: string;
  audioURL: string;
  videoURL: string;
  documentURL: string;
  sessionId: number;
  active: boolean;
  timestamp: string;
}

export interface ISession {
  id: number;
  status: string;
  provider: {
    id: number;
    name: string;
    email: string;
    profession: string;
    photo: string;
  };
  agent: {
    id: number;
    name: string;
    email: string;
    photo: string;
  };
  send_dttm: string;
  prov_rate: number;
  agent_rate: number;
  feedback: string;
  messages: IMessage[];
}

export enum WorkflowType {
  PS = "PS",
  ES = "ES",
  OS = "OS",
  PB = "PB",
  ELS = "ELS",
}

export enum WorkflowState {
  UNASSIGNED = "UNASSIGNED",
  ASSIGNED = "ASSIGNED",
  COMPLETED = "COMPLETED",
  ESCALATED = "ESCALATED",
}

export interface IWorkflow {
  id: number;
  object: any;
  object_id: number;
  type: WorkflowType;
  state: WorkflowState;
  comment: string;
  approved: boolean;
  evaluated: boolean;
  additional_approval: boolean;
  additional_approved: boolean;
  additional_approver: {
    id: number;
    name: string;
    email: string;
    photo: string;
  };
  user: {
    id: number;
    name: string;
    email: string;
    photo: string;
  };
  agent: {
    id: number;
    name: string;
    email: string;
    photo: string;
  };
  created_at: string;
  updated_at: string;
  pending: number;
}

export interface IWorkflowMessage {
  id: string;
  senderMail: string;
  receiverMail: string;
  messageType: string;
  body: string;
  imageURL: string;
  audioURL: string;
  videoURL: string;
  documentURL: string;
  timestamp: string;
  read: boolean;
}

export interface IAuthorizationEvent {
  id: number;
  event_type: string;
  customer: string;
  customer_photo: string;
  appointment: IAppointment;
  entity_link: ILink;
  offering: IOffering;
}

export interface IAccount {
  id: number;
  type: string;
  email: string;
  created: string;
}
