import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useCTX } from "../state";

type RouteProps = { children: JSX.Element | JSX.Element[] };

export const PrivateRoute = ({ children }: RouteProps) => {
  const {
    state: { user },
  } = useCTX();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/auth/login", { replace: true });
    }
  }, [navigate, user]);

  if (!user) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};

export const PublicRoute = ({ children }: RouteProps) => {
  const {
    state: { user },
  } = useCTX();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  if (user) {
    return null;
  }

  return <Fragment>{children}</Fragment>;
};
