import { Avatar, Button, Image, Input, Spin, Upload, UploadFile } from "antd";
import {
  addDoc,
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  Unsubscribe,
  updateDoc,
  where,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { nanoid } from "nanoid";
import { useCallback, useEffect, useRef, useState } from "react";
import AudioPlayer from "react-h5-audio-player";
import { FaRegFilePdf } from "react-icons/fa";
import { IoMdPaperPlane } from "react-icons/io";
import { MdOutlinePermMedia } from "react-icons/md";
import { Player } from "video-react";
import { db, storage } from "../../lib/firebase";
import { IWorkflow, IWorkflowMessage, WorkflowState } from "../../lib/model";
import { getBase64, getDomain } from "../../lib/utils";
import { useCTX } from "../../state";

type Props = {
  workflow: IWorkflow;
};

export default function WorkflowChat({ workflow }: Props) {
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<IWorkflowMessage[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const {
    state: { user },
  } = useCTX();
  const unsubRef = useRef<Unsubscribe>();

  const onMessage = async () => {
    setSending(true);
    setMessage("");

    const cref = collection(
      db,
      "workflows",
      `${workflow.id}_workflow@${getDomain()}`,
      "messages"
    );

    addDoc(cref, {
      senderMail: user?.email,
      receiverMail: workflow?.agent.email,
      messageType: "text",
      body: message,
      imageURL: "",
      audioURL: "",
      videoURL: "",
      timestamp: new Date().getTime().toString(),
      read: false,
    }).then(() => setSending(false));
  };

  const onMedia = useCallback(
    (file: UploadFile) => {
      setSending(true);

      const ftype = file.type?.split("/")[0];
      const fref = ref(
        storage,
        `workflows/${workflow.id}_workflow@${getDomain()}/${ftype}s/${nanoid(
          5
        )}.${file.type?.split("/")[1]}`
      );

      const uploader = uploadBytesResumable(fref, file.originFileObj!);

      uploader.on("state_changed", null, null, () => {
        getDownloadURL(uploader.snapshot.ref).then((url) => {
          const cref = collection(
            db,
            "workflows",
            `${workflow.id}_workflow@${getDomain()}`,
            "messages"
          );

          const message = {
            senderMail: user?.email,
            receiverMail: workflow?.agent.email,
            messageType: ftype,
            body: "",
            imageURL: "",
            audioURL: "",
            videoURL: "",
            documentURL: "",
            timestamp: new Date().getTime().toString(),
            read: false,
          };

          if (ftype === "image") message.imageURL = url;
          else if (ftype === "audio") message.audioURL = url;
          else if (ftype === "video") message.videoURL = url;
          else if (ftype === "application") message.documentURL = url;

          addDoc(cref, message).then(() => setSending(false));
        });
      });
    },
    [user, workflow]
  );

  useEffect(() => {
    setLoading(true);
    const cref = collection(
      db,
      "workflows",
      `${workflow.id}_workflow@${getDomain()}`,
      "messages"
    );
    let q = query(cref, orderBy("timestamp", "asc"));
    unsubRef.current = onSnapshot(q, async (snapshot) => {
      setMessages(snapshot.docs.map((d) => ({ id: d.id, ...d.data() } as any)));
      setLoading(false);

      if (workflow.state === WorkflowState.COMPLETED) {
        unsubRef.current?.();
      } else {
        let q = query(
          cref,
          where("receiverMail", "==", user?.email),
          where("read", "==", false),
          orderBy("timestamp", "asc")
        );

        const snapshot = await getDocs(q);

        snapshot.docs.forEach((d) => updateDoc(d.ref, { read: true }));
      }
    });

    return () => unsubRef.current?.();
  }, [workflow.id, workflow.state, user?.email]);

  useEffect(() => {
    containerRef.current?.scrollTo({
      behavior: "smooth",
      top: containerRef.current.scrollHeight,
    });
  }, [messages]);

  return (
    <div className="my-8">
      {loading ? (
        <div className="h-full flex justify-center items-center">
          <Spin />
        </div>
      ) : (
        <div className="w-full h-full">
          <div className="flex h-[5%]">
            <Avatar
              src={workflow.agent.photo || "/images/no-avatar.jpeg"}
              className="mr-2"
            />
            <div className="flex flex-col">
              <span style={{ fontSize: "0.7rem" }}>{workflow.agent.name}</span>
              <span style={{ fontSize: "0.7rem" }} className="text-gray-500">
                {workflow.agent.email}
              </span>
            </div>
          </div>
          <div
            ref={containerRef}
            className="h-[325px] py-4 mt-4 overflow-y-scroll"
          >
            <div className="flex flex-col justify-end">
              {messages.map((m) => (
                <div
                  key={m.id}
                  className="flex flex-col border border-solid border-gray-100 mt-4 p-4 w-1/2"
                  style={{
                    alignSelf:
                      user?.email === m.senderMail ? "flex-end" : "flex-start",
                  }}
                >
                  <span style={{ fontSize: "0.6rem" }} className="mb-2">
                    {m.senderMail}
                  </span>
                  {m.messageType === "image" ? (
                    <Image
                      src={m.imageURL}
                      alt={m.imageURL}
                      width={100}
                      height={100}
                      className="object-cover"
                    />
                  ) : m.messageType === "audio" ? (
                    <AudioPlayer src={m.audioURL} showJumpControls={false} />
                  ) : m.messageType === "video" ? (
                    <Player
                      src={m.videoURL}
                      fluid={false}
                      width={"100%" as any}
                      height={200}
                    />
                  ) : m.messageType === "application" ? (
                    <a
                      href={m.documentURL}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaRegFilePdf className="text-5xl mt-2" />
                    </a>
                  ) : (
                    <span className="text-xs">{m.body}</span>
                  )}
                  <span
                    className="text-gray-500 mt-2 ml-auto"
                    style={{ fontSize: "0.7rem" }}
                  >
                    {new Date(parseInt(m.timestamp)).toLocaleTimeString()}
                  </span>
                </div>
              ))}
            </div>
          </div>
          {workflow.state !== WorkflowState.COMPLETED && (
            <div className="h-[5%] flex items-center">
              <Upload
                fileList={[]}
                accept="image/*,audio/*,video/*,.pdf"
                onChange={({ fileList }) => onMedia(fileList[0])}
                previewFile={getBase64 as any}
                className="mr-1"
              >
                <Button icon={<MdOutlinePermMedia size={10} />} />
              </Upload>
              <Input
                placeholder="Write a message..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Button
                className="ml-4"
                icon={<IoMdPaperPlane size={10} />}
                disabled={!message}
                loading={sending}
                onClick={onMessage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
