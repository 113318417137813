import { Button, Checkbox, Dropdown, Form, Input, Typography } from "antd";
import { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { MPhoneCountry, MPhoneType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  country: string;
  phone_type: string;
  primary: boolean;
};

export default function PhoneNew() {
  useTitle("New Phone");

  const [info, setInfo] = useState<TInfo>({
    country: "",
    phone_type: "",
    primary: false,
  });

  const navigate = useNavigate();
  const { dispatch } = useCTX();
  const [request, loading] = useRequest();

  const onCreate = (values: any) => {
    const _info = {
      ...values,
      ...info,
      token: localStorage.getItem("token"),
      country: info.country === "11" ? 1 : parseInt(info.country),
      operation: "A",
    };

    request(
      "post",
      "phones/api/webaddupdate/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "PHONE",
            description: "Phone created successfully!",
          })
        );
      },
      _info
    );
  };

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <IoAdd className="mr-2" /> Phone
        </Typography.Title>
        <Form
          labelCol={{ span: 8 }}
          labelAlign="left"
          initialValues={{ phone_name: "PH" }}
          onFinish={onCreate}
        >
          <Form.Item
            label="Name"
            name="phone_name"
            rules={[{ required: true, message: "Please input phone name!" }]}
          >
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Country"
            name="country"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.country) {
                    return Promise.reject("Please input country!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MPhoneCountry.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      country: MPhoneCountry.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.country
                  ? MPhoneCountry.getKey(info.country)
                  : "Select the country"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Number"
            name="phone_number"
            rules={[{ required: true, message: "Please input phone number!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.phone_type) {
                    return Promise.reject("Please input phone type!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MPhoneType.keys.map((v) => ({
                  key: v,
                  label: v,
                  onClick: () =>
                    setInfo({
                      ...info,
                      phone_type: MPhoneType.getValue(v),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.phone_type
                  ? MPhoneType.getKey(info.phone_type)
                  : "Select the type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Checkbox
            onChange={(e) => setInfo({ ...info, primary: e.target.checked })}
          >
            primary
          </Checkbox>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
