import { Navigate, Route, Routes } from "react-router-dom";
import LinkList from "./List";
import LinkNew from "./New";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<LinkList />} />
      <Route path="new" element={<LinkNew />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
