import {
  Button,
  Image,
  Modal,
  Spin,
  Table,
  TableProps,
  Typography,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import {
  MdAdd,
  MdDeleteOutline,
  MdEdit,
  MdOutlinePermMedia,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { IPhoto } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";
import { FaEye } from "react-icons/fa";

export default function GalleryList() {
  useTitle("Gallery");

  const [photos, setPhotos] = useState<IPhoto[]>([]);

  const [deletePhoto, setDeletePhoto] = useState<IPhoto | null>(null);
  const [viewPhoto, setViewPhoto] = useState<IPhoto | null>(null);

  const { dispatch } = useCTX();
  const navigate = useNavigate();

  const [deleteRequest, deleting] = useRequest();
  const [listRequest, loading] = useRequest(true);

  const columns: TableProps<IPhoto>["columns"] = useMemo(
    () => [
      {
        key: "title",
        title: "Title",
        dataIndex: "title",
      },
      {
        key: "photo",
        title: "Photo",
        dataIndex: "photo",
        render: (_: any, p: any) => (
          <span>{p.photo.split("/").slice(-1)[0]}</span>
        ),
      },
      {
        key: "service",
        title: "Service",
        dataIndex: "service",
      },
      {
        key: "action",
        title: "Action",
        dataIndex: "action",
        render: (_, p: any) => (
          <div>
            <Button
              className="text-xs mr-2"
              icon={<FaEye />}
              onClick={() => setViewPhoto(p)}
            />
            <Button
              className="text-xs mr-2"
              icon={<MdDeleteOutline />}
              onClick={() => setDeletePhoto(p)}
            />
            <Button
              className="text-xs"
              icon={<MdEdit />}
              onClick={() => navigate(`/provider/gallery/${p.id}/edit`)}
            />
          </div>
        ),
      },
    ],
    [navigate]
  );

  const onDelete = () => {
    deleteRequest(
      "post",
      "photos/api/webdelete/",
      () => {
        setPhotos((old) => old.filter((p) => p.id !== deletePhoto?.id));
        setDeletePhoto(null);
        dispatch(
          setAlert({
            type: "success",
            message: "PHOTO",
            description: "Photo deleted successfully!",
          })
        );
      },
      {
        token: localStorage.getItem("token"),
        photo_id: deletePhoto?.id,
      }
    );
  };

  useEffect(() => {
    listRequest(
      "post",
      "photos/api/weblist/",
      (res) => {
        setPhotos(res.data.photos);
      },
      {
        token: localStorage.getItem("token"),
      }
    );
  }, [listRequest]);

  if (loading) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <Modal
        open={!!deletePhoto}
        title="Delete Photo"
        onCancel={() => setDeletePhoto(null)}
        okText="Delete"
        confirmLoading={deleting}
        onOk={onDelete}
      >
        <span className="text-xs">
          Are you sure that you want to delete photo '{deletePhoto?.title}
          '?
        </span>
      </Modal>
      <Modal
        open={!!viewPhoto}
        title={viewPhoto?.title}
        onCancel={() => setViewPhoto(null)}
        footer={null}
      >
        <div className="flex justify-center">
          <Image
            src={viewPhoto?.photo}
            preview={false}
            className="my-5 max-h-52 w-full"
          />
        </div>
      </Modal>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <div className="pb-4 flex justify-between items-center">
          <Typography.Title
            level={4}
            className="font-poppins flex items-center"
          >
            <MdOutlinePermMedia className="mr-2" /> Gallery
          </Typography.Title>
          <Button
            className="text-xs"
            icon={<MdAdd />}
            onClick={() => navigate("/provider/gallery/new")}
          >
            Add
          </Button>
        </div>
        <Table columns={columns} dataSource={photos} pagination={false} />
      </div>
    </PrivateRoute>
  );
}
