import { Avatar, Button, Dropdown, Image, Menu, MenuProps, Spin } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiFillBank, AiOutlineHome, AiOutlineUserAdd } from "react-icons/ai";
import { CiLock } from "react-icons/ci";
import { FaArrowRight, FaFacebook, FaList } from "react-icons/fa";
import { FiLogIn } from "react-icons/fi";
import { GrServices } from "react-icons/gr";
import { IoMdBusiness, IoMdCalendar } from "react-icons/io";
import {
  IoCogOutline,
  IoGitNetwork,
  IoLocationOutline,
  IoMailOutline,
  IoPhonePortraitOutline,
} from "react-icons/io5";
import {
  MdArrowDropDown,
  MdArrowDropUp,
  MdLocalOffer,
  MdLockOutline,
  MdOutlineDashboard,
  MdOutlineFactCheck,
  MdOutlinePermMedia,
  MdOutlineSyncProblem,
  MdSupport,
  MdSupportAgent,
} from "react-icons/md";
import { PiUsersThreeLight } from "react-icons/pi";
import { RiUser2Fill, RiUser3Line } from "react-icons/ri";
import { TbCertificate } from "react-icons/tb";
import { TiBusinessCard } from "react-icons/ti";
import { useLocation, useNavigate } from "react-router-dom";
import { useRequest } from "../hooks/useRequest";
import { useCTX } from "../state";
import { setUser } from "../state/actionCreators";
import SwitchButton from "./SwitchButton";

const menu = new Map<string, string>([
  ["", "home"],
  ["calendar", "calendar"],
  ["session", "session"],
  ["workflow", "workflow"],
  ["support", "support"],
  ["service", "service"],
  ["authorization", "authorization"],
  ["about", "about"],
]);

function Navbar() {
  const {
    state: { settings, user, provider },
    dispatch,
  } = useCTX();
  const navigate = useNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();
  const [request, loggingOut] = useRequest();

  const pathname = location.pathname.split("/").slice(-1)[0];
  const [current, setCurrent] = useState(
    menu.has(pathname) ? menu.get(pathname)! : ""
  );
  const [lngs, setLngs] = useState(["en", "es", "pt"]);
  const [menuOpen, setMenuOpen] = useState(false);

  const logout = useCallback(() => {
    request("post", "users/api/weblogout/", () => {
      dispatch(setUser(null));
      localStorage.removeItem("token");
      setCurrent("home");
    });
  }, [dispatch, request]);

  const lngMenu = useMemo(() => {
    const menu = lngs.map((l) => ({
      label: l.toUpperCase(),
      key: l,
      icon: (
        <Image
          src={`/images/flags/${l}.svg`}
          alt="en"
          width={20}
          height={20}
          className="pr-1 pt-1"
          preview={false}
        />
      ),
    })) as any;
    menu[0].children = menu.slice(1);
    return menu[0];
  }, [lngs]);

  const customerMenu: MenuProps["items"] = useMemo(
    () => [
      {
        key: "settings",
        label: "Settings",
        icon: <IoCogOutline />,
        onClick: () => navigate("/customer/settings"),
      },
      {
        key: "profile",
        label: "Profile",
        icon: <RiUser3Line />,
        onClick: () => navigate("/customer/profile"),
      },
      {
        key: "email-addresses",
        label: "Email Addresses",
        icon: <IoMailOutline />,
        onClick: () => navigate("/customer/emails"),
      },
      {
        key: "phones",
        label: "Phones",
        icon: <IoPhonePortraitOutline />,
        onClick: () => navigate("/customer/phones"),
      },
      {
        key: "addresses",
        label: "Addresses",
        icon: <IoLocationOutline />,
        onClick: () => navigate("/customer/addresses"),
      },
      {
        key: "social-networks",
        label: "Social Networks",
        icon: <FaFacebook />,
        onClick: () => navigate("/customer/social-networks"),
      },
      {
        key: "reset-password",
        label: "Reset Password",
        icon: <MdLockOutline />,
        onClick: () => navigate("/customer/reset-password"),
      },
      {
        key: "logout",
        label: "Logout",
        icon: <FaArrowRight />,
        onClick: logout,
      },
    ],
    [logout, navigate]
  );

  const providerMenu: MenuProps["items"] = useMemo(
    () => [
      {
        key: "dashboard",
        label: "Dashboard",
        icon: <MdOutlineDashboard />,
        onClick: () => navigate("/provider/dashboard"),
      },
      {
        key: "profile",
        label: "Profile",
        icon: <RiUser3Line />,
        onClick: () => navigate("/provider/profile"),
      },
      {
        key: "entities",
        label: "Legal Entities",
        icon: <IoMdBusiness />,
        onClick: () => navigate("/provider/entities"),
      },
      {
        key: "links",
        label: "Legal Entity Links",
        icon: <TiBusinessCard />,
        onClick: () => navigate("/provider/links"),
      },
      {
        key: "states-serviced",
        label: "States Serviced",
        icon: <FaList />,
        onClick: () => navigate("/provider/states-serviced"),
      },
      {
        key: "certificates",
        label: "Certificates",
        icon: <TbCertificate />,
        onClick: () => navigate("/provider/certificates"),
      },
      {
        key: "offerings",
        label: "Offerings",
        icon: <MdLocalOffer />,
        onClick: () => navigate("/provider/offerings"),
      },
      {
        key: "gallery",
        label: "Gallery",
        icon: <MdOutlinePermMedia />,
        onClick: () => navigate("/provider/gallery"),
      },
      {
        key: "accounts",
        label: "Bank Accounts",
        icon: <AiFillBank />,
        onClick: () => navigate("/provider/accounts"),
      },
      {
        key: "disputes",
        label: "Disputes",
        icon: <MdOutlineSyncProblem />,
        onClick: () => navigate("/provider/disputes"),
      },
      {
        key: "dispute-facts",
        label: "Dispute Facts",
        icon: <MdOutlineFactCheck />,
        onClick: () => navigate("/provider/dispute-facts"),
      },
      {
        key: "logout",
        label: "Logout",
        icon: <FaArrowRight />,
        onClick: logout,
      },
    ],
    [logout, navigate]
  );

  const mainMenu = useMemo(() => {
    const _ = [
      lngMenu,
      {
        label: "Home",
        key: "home",
        icon: <AiOutlineHome />,
      },
      {
        label: "Service",
        key: "service",
        icon: <GrServices />,
      },
      {
        label: "About",
        key: "about",
        icon: <PiUsersThreeLight />,
      },
    ];

    if (user) {
      if (settings.type === "provider") {
        _.push({
          label: "Calendar",
          key: "calendar",
          icon: <IoMdCalendar />,
        });

        _.push({
          label: "Support",
          key: "support",
          icon: <MdSupport />,
        });
      }

      if (user?.agent_status === "A") {
        _.push({
          label: "Workflow",
          key: "workflow",
          icon: <IoGitNetwork />,
        });

        if (!user.agent_is_manager) {
          _.push({
            label: "Session",
            key: "session",
            icon: <MdSupportAgent />,
          });
        }
      } else {
        _.push({
          label: "Authorization",
          key: "authorization",
          icon: <CiLock />,
        });
      }
    }

    return _;
  }, [settings, lngMenu, user]);

  useEffect(() => {
    setCurrent(menu.has(pathname) ? menu.get(pathname)! : "");
  }, [pathname]);

  return (
    <div className="flex md:w-3/4 w-[90%] py-8 justify-between mx-auto">
      <div className="w-2/4 md:w-1/4">
        <Image
          src={`/images/logos/${settings.type}.png`}
          preview={false}
          className="pt-5 pr-10 cursor-pointer"
          onClick={() => {
            setCurrent("home");
            navigate("/");
          }}
        />
      </div>
      <div className="w-3/4 flex flex-row-reverse md:flex-row justify-start md:justify-end items-center">
        <Menu
          className={`w-1/6 md:w-3/6`}
          mode="horizontal"
          selectedKeys={[current]}
          onClick={(i) => {
            const isLng = ["en", "es", "pt"].includes(i.key);
            if (isLng) {
              const _lngs = lngs.filter((l) => l !== i.key);
              setLngs([i.key, ..._lngs]);
              i18n.changeLanguage(i.key);
            } else {
              setCurrent(i.key);
              navigate(i.key === "home" ? "" : i.key);
            }
          }}
          items={mainMenu}
        />
        {user &&
          (user.prov_status === "R" ? (
            <Button icon={<RiUser2Fill size={10} />} className="mx-2">
              <span className="text-xs">Applied</span>
            </Button>
          ) : user.prov_status === "N" && user.agent_status === "N" ? (
            <Button
              type="primary"
              icon={<RiUser2Fill size={10} />}
              className="mx-2"
              onClick={() => navigate("/become-provider")}
            >
              <span className="text-xs">Become Provider</span>
            </Button>
          ) : user.agent_status !== "A" ? (
            <SwitchButton />
          ) : null)}
        {user ? (
          loggingOut ? (
            <Spin size="small" className="ml-8" />
          ) : settings.type === "customer" ? (
            <Dropdown
              menu={{ items: customerMenu }}
              trigger={["click"]}
              onOpenChange={(o) => setMenuOpen(o)}
            >
              <div className="ml-4 flex items-center cursor-pointer">
                <Avatar
                  className="w-10 h-10 hover:opacity-90 mr-2"
                  src={user?.avatar || "/images/no-avatar.jpeg"}
                />
                <span style={{ fontSize: "0.7rem" }}>{user?.display_name}</span>
                {menuOpen ? (
                  <MdArrowDropUp className="ml-2" />
                ) : (
                  <MdArrowDropDown className="ml-2" />
                )}
              </div>
            </Dropdown>
          ) : provider ? (
            <Dropdown
              menu={{ items: providerMenu }}
              trigger={["click"]}
              onOpenChange={(o) => setMenuOpen(o)}
            >
              <div className="ml-4 flex items-center cursor-pointer">
                <Avatar
                  className="w-10 h-10 hover:opacity-90 mr-2"
                  src={provider.photo || "/images/no-avatar.jpeg"}
                />
                <span style={{ fontSize: "0.7rem" }}>
                  {provider.profession}
                </span>
                {menuOpen ? (
                  <MdArrowDropUp className="ml-2" />
                ) : (
                  <MdArrowDropDown className="ml-2" />
                )}
              </div>
            </Dropdown>
          ) : null
        ) : (
          <div className="ml-0 md:ml-10 flex flex-nowrap">
            <Button
              onClick={() => {
                navigate("/auth/register");
                setCurrent("");
              }}
              type="primary"
              icon={<AiOutlineUserAdd size={10} />}
              style={{ marginRight: 4 }}
            >
              <span className="text-xs">Register</span>
            </Button>
            <Button
              icon={<FiLogIn size={10} />}
              onClick={() => {
                navigate("/auth/login");
                setCurrent("");
              }}
            >
              <span className="text-xs">Login</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Navbar;
