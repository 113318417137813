import { Avatar, Input, List, Modal } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRequest } from "../../hooks/useRequest";
import { ISession, IWorkflow, WorkflowState } from "../../lib/model";
import { useCTX } from "../../state";
import { setAlert } from "../../state/actionCreators";

type Props = {
  setShow: Function;
  workflow: IWorkflow;
  setWorkflow: Function;
};

export default function AssignWorkflow({
  setShow,
  workflow,
  setWorkflow,
}: Props) {
  const [query, setQuery] = useState("");
  const [agents, setAgents] = useState<ISession["agent"][]>([]);
  const [activeAgent, setActiveAgent] = useState<ISession["agent"] | null>(
    null
  );

  const timeoutRef = useRef<NodeJS.Timeout>();

  const { dispatch } = useCTX();
  const [listRequest, loading] = useRequest();
  const [assignRequest, assigning] = useRequest();

  const getAgents = useCallback(
    (q?: string) => {
      timeoutRef.current = setTimeout(() => {
        listRequest(
          "post",
          "agents/api/weblist/",
          (res) => {
            setAgents(res.data.agent_list);
          },
          {
            token: localStorage.getItem("token"),
            search_string: q,
            pageRequested: 1,
          }
        );
      }, 2000);
    },
    [listRequest]
  );

  const onAssign = () => {
    assignRequest(
      "post",
      "workflows/api/webassign/",
      () => {
        dispatch(
          setAlert({
            type: "success",
            message: "ASSIGN",
            description: `Agent "${activeAgent?.email}" assigned to workflow "${workflow.id}" successfully.`,
          })
        );
        setWorkflow({
          ...workflow,
          state: WorkflowState.ASSIGNED,
          agent: activeAgent,
        });
        setShow(false);
      },
      {
        token: localStorage.getItem("token"),
        flow_id: workflow.id,
        agent_id: activeAgent?.id,
      }
    );
  };

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    if (query.trim().length >= 2) {
      getAgents(query);
    } else {
      getAgents();
    }
  }, [query, getAgents]);

  return (
    <Modal
      open={true}
      title={`Assign Workflow (${workflow.id})`}
      onCancel={() => setShow(null)}
      confirmLoading={assigning}
      okButtonProps={{ disabled: !activeAgent }}
      onOk={onAssign}
    >
      <div className="my-8">
        <Input
          placeholder="Search Agent..."
          value={query}
          onChange={(e) => setQuery(e.target.value.trim())}
        />
        <List
          loading={loading}
          itemLayout="vertical"
          dataSource={agents}
          className="my-4"
          renderItem={(item) => (
            <List.Item
              className="cursor-pointer"
              style={{
                backgroundColor:
                  activeAgent?.id === item.id
                    ? "rgba(0, 0, 0, 0.02)"
                    : "initial",
              }}
              onClick={() => setActiveAgent(item)}
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={item.photo || "/images/no-avatar.jpeg"}
                    className="ml-2"
                  />
                }
                description={item.email}
              />
            </List.Item>
          )}
        />
      </div>
    </Modal>
  );
}
