import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { Button, Dropdown, Form, Input, Spin, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { PrivateRoute } from "../../../components/Route";
import { useRequest } from "../../../hooks/useRequest";
import { useTitle } from "../../../hooks/useTitle";
import { MAddressType } from "../../../lib/model";
import { useCTX } from "../../../state";
import { setAlert } from "../../../state/actionCreators";

type TInfo = {
  name: string;
  address1: string;
  address2: string;
  zipcode: string;
  complement: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  reference: string;
  pobox: string;
  address_type: string;
  lat: number;
  lng: number;
};

const defaultCenter = {
  lat: 7.2905715,
  lng: 80.6337262,
};
const libraries = ["places"] as any[];

export default function AddressNew() {
  useTitle("New Address");

  const [center, setCenter] = useState(defaultCenter);
  const [marker, setMarker] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const [info, setInfo] = useState<TInfo>({
    name: "",
    address1: "",
    address2: "",
    zipcode: "",
    complement: "",
    number: "",
    neighborhood: "",
    city: "",
    state: "",
    country: "",
    reference: "",
    pobox: "",
    address_type: "",
    lat: defaultCenter.lat,
    lng: defaultCenter.lng,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY!,
    libraries,
  });
  const navigate = useNavigate();
  const {
    dispatch,
    state: { user },
  } = useCTX();
  const [request, loading] = useRequest();
  const [form] = Form.useForm();

  const onCreate = () => {
    const _info = {
      email: user?.email,
      ...info,
      lat: info.lat.toFixed(10),
      lng: info.lng.toFixed(10),
    };

    request(
      "post",
      "addresses/api/addUpdateAddress/",
      () => {
        navigate(-1);
        dispatch(
          setAlert({
            type: "success",
            message: "ADDRESS",
            description: "Address added successfully!",
          })
        );
      },
      _info
    );
  };

  const extractPlace = useCallback(
    (place: any) => {
      const _info: any = {
        name: place.formatted_address
          .split(",")
          .slice(0, 2)
          .join(",")
          .slice(0, 30),
        address1: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        zipcode: "",
        country: "",
        state: "",
        city: "",
        number: "",
      };

      for (const component of place.address_components) {
        if (component.types.includes("postal_code")) {
          _info.zipcode = component.short_name;
        } else if (component.types.includes("country")) {
          _info.country = component.short_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          _info.state = component.short_name;
        } else if (component.types.includes("locality")) {
          _info.city = component.short_name;
        } else if (component.types.includes("street_number")) {
          _info.number = component.short_name;
        }
      }

      if (!_info.number) {
        const match = _info.name.match(
          /(\b\d+[\/\d]*)\b(?!.*\b\d+[\/\d]*\b.*,)/g
        );
        _info.number = match ? match[0] : "";
      }

      setInfo((old) => ({
        ...old,
        ..._info,
      }));

      form.setFieldsValue(_info);
    },
    [form]
  );

  useEffect(() => {
    if (isLoaded) {
      navigator.geolocation.getCurrentPosition(
        ({ coords: { latitude, longitude } }) => {
          setCenter({ lat: latitude, lng: longitude });
        }
      );
    }
  }, [isLoaded]);

  useEffect(() => {
    if (marker) {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: { ...marker } }).then((res) => {
        const place = res.results[0];
        extractPlace(place);
      });
    }
  }, [marker, extractPlace]);

  if (!isLoaded) {
    return (
      <div className="h-[100vh] flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  return (
    <PrivateRoute>
      <div className="w-full lg:w-3/4 mx-auto">
        <BackButton />
        <Typography.Title
          level={4}
          className="font-poppins flex items-center pb-4"
        >
          <IoAdd className="mr-2" /> Address
        </Typography.Title>
        <GoogleMap
          mapContainerStyle={{
            width: "100%",
            height: "300px",
            borderRadius: "0.5rem",
          }}
          zoom={10}
          center={center}
          options={{
            keyboardShortcuts: false,
            disableDefaultUI: true,
            fullscreenControl: true,
            disableDoubleClickZoom: true,
          }}
          onClick={(e) => {
            setMarker({ lat: e.latLng!.lat(), lng: e.latLng!.lng() });
          }}
        >
          {marker && <MarkerF position={{ ...marker }} />}
        </GoogleMap>
        <Form
          form={form}
          labelCol={{ span: 8 }}
          labelAlign="left"
          className="my-8"
          onFinish={onCreate}
          onValuesChange={(_, values) =>
            setInfo((old) => ({ ...old, ...values }))
          }
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Please input address name!" }]}
          >
            <Input
              value={info.name}
              onChange={(e) => setInfo({ ...info, name: e.target.value })}
            />
          </Form.Item>
          <Form.Item
            label="Type"
            name="address_type"
            rules={[
              {
                required: true,
                validator: () => {
                  if (!info.address_type) {
                    return Promise.reject("Please input address type!");
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Dropdown
              trigger={["click"]}
              className="mb-2"
              menu={{
                items: MAddressType.keys.map((k) => ({
                  key: k,
                  label: k,
                  onClick: () =>
                    setInfo({
                      ...info,
                      address_type: MAddressType.getValue(k),
                    }),
                })),
              }}
            >
              <div className="text-xs text-gray-500 flex items-center cursor-pointer">
                {info.address_type
                  ? MAddressType.getKey(info.address_type)
                  : "Select type"}
                <BiChevronDown className="ml-2" />
              </div>
            </Dropdown>
          </Form.Item>
          <Form.Item
            label="Address 1"
            name="address1"
            rules={[{ required: true, message: "Please input the address 1!" }]}
          >
            <Input value={info.address1} disabled />
          </Form.Item>
          <Form.Item
            label="City"
            name="city"
            rules={[{ required: true, message: "Please input the city!" }]}
          >
            <Input value={info.city} disabled />
          </Form.Item>
          <Form.Item
            label="State"
            name="state"
            rules={[{ required: true, message: "Please input the state!" }]}
          >
            <Input value={info.state} disabled />
          </Form.Item>
          <Form.Item
            label="Country (ISO)"
            name="country"
            rules={[
              { required: true, message: "Please input the country iso code!" },
            ]}
          >
            <Input value={info.country} disabled />
          </Form.Item>
          <Form.Item label="Address 2" name="address2">
            <Input value={info.address2} disabled />
          </Form.Item>
          <Form.Item label="Neighborhood" name="neighborhood">
            <Input value={info.neighborhood} disabled />
          </Form.Item>
          <Form.Item label="Zipcode" name="zipcode">
            <Input value={info.zipcode} disabled />
          </Form.Item>
          <Form.Item label="Street Number" name="number">
            <Input
              value={info.number}
              onChange={(e) => setInfo({ ...info, number: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="P.O Box" name="pobox">
            <Input
              value={info.pobox}
              onChange={(e) => setInfo({ ...info, pobox: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Reference" name="reference">
            <Input
              value={info.reference}
              onChange={(e) => setInfo({ ...info, reference: e.target.value })}
            />
          </Form.Item>
          <Form.Item label="Complement" name="complement">
            <Input
              value={info.complement}
              onChange={(e) => setInfo({ ...info, complement: e.target.value })}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Button
              className="text-xs"
              icon={<IoAdd size={10} />}
              loading={loading}
              htmlType="submit"
            >
              Create
            </Button>
          </div>
        </Form>
      </div>
    </PrivateRoute>
  );
}
