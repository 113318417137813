import { Navigate, Route, Routes } from "react-router-dom";
import AccountLayout from "../provider/accounts/Layout";
import CertificateLayout from "../provider/certificates/Layout";
import DisputeLayout from "../provider/disputes/Layout";
import EntityLayout from "../provider/entities/Layout";
import FactLayout from "../provider/facts/Layout";
import GalleryLayout from "../provider/gallery/Layout";
import LinkLayout from "../provider/links/Layout";
import OfferingLayout from "../provider/offerings/Layout";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import StatesServiced from "./StatesServiced";
import Validator from "./Validator";

export default function Layout() {
  return (
    <Routes>
      <Route path="/" element={<Validator />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<Profile />} />
        <Route path="states-serviced" element={<StatesServiced />} />
        <Route path="entities/*" element={<EntityLayout />} />
        <Route path="links/*" element={<LinkLayout />} />
        <Route path="certificates/*" element={<CertificateLayout />} />
        <Route path="accounts/*" element={<AccountLayout />} />
        <Route path="offerings/*" element={<OfferingLayout />} />
        <Route path="gallery/*" element={<GalleryLayout />} />
        <Route path="disputes/*" element={<DisputeLayout />} />
        <Route path="dispute-facts/*" element={<FactLayout />} />
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Route>
    </Routes>
  );
}
