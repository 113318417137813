import { Navigate, Route, Routes } from "react-router-dom";
import EmailList from "./List";
import EmailNew from "./New";
import EmailVerify from "./Verify";

export default function Layout() {
  return (
    <Routes>
      <Route index element={<EmailList />} />
      <Route path="new" element={<EmailNew />} />
      <Route path="verify/:vid" element={<EmailVerify />} />
      <Route path="*" element={<Navigate to={"/"} replace />} />
    </Routes>
  );
}
